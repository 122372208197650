import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";
import { H3, Divider, BodyText } from "@/components/typography";
import type { ExperienceSectionStoryblok } from "@/component-types-sb";

type ExperienceSectionPops = {
  blok: ExperienceSectionStoryblok;
};

const ExperienceSection = ({ blok }: ExperienceSectionPops) => (
  <section
    className="py-8 mx-2 lg:mx-4 text-center"
    {...storyblokEditable(blok)}
  >
    <H3 as="h2" colorScheme="secondary">
      {blok.headline}
    </H3>
    <BodyText className="max-w-2xl mx-auto mt-4 text-center">
      {blok.text}
    </BodyText>

    <Divider />

    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 text-left">
      {blok.experiences?.map((nestedBlok) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
    </div>
  </section>
);

ExperienceSection.blockName = "experience_section";

export default ExperienceSection;
