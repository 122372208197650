"use client";

import { Fragment, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { MagnifyingGlassIcon, MapPinIcon } from "@heroicons/react/24/outline";

const iconDefinitions = {
  search: MagnifyingGlassIcon,
  location: MapPinIcon,
};

type IconName = keyof typeof iconDefinitions;

type Item = { id?: number; name: string; uuid?: string };

type AutocompleteProps = {
  data: Item[];
  value?: Item | null;
  name?: string;
  label?: string;
  placeholder?: string;
  icon?: IconName;
};

const Autocomplete = ({
  data = [],
  value = null,
  name,
  label,
  placeholder,
  icon,
}: AutocompleteProps) => {
  const [selected, setSelected] = useState<Item | null>(value);
  const [query, setQuery] = useState("");
  const Icon = icon ? iconDefinitions[icon] : Fragment;

  const filteredData =
    query === ""
      ? data
      : data.filter((item) =>
          item?.name
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  return (
    <Combobox name={name} value={selected} onChange={setSelected}>
      <div className="relative w-full">
        <Combobox.Label className="block text-xs uppercase font-medium text-gray-600 mb-1">
          {label}
        </Combobox.Label>
        <div className="flex relative w-full items-center justify-center cursor-default overflow-hidden bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-brand-primary50 sm:text-sm">
          <Icon className="h-5 w-5 ml-2 text-gray-900" />

          <Combobox.Input
            className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0 outline-none"
            displayValue={(item: Item) => item?.name}
            onChange={(event) => setQuery(event.target.value)}
            placeholder={placeholder}
            autoComplete="off"
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Combobox.Button>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery("")}
        >
          <Combobox.Options
            hold
            className="absolute mt-1 max-h-60 w-full overflow-auto bg-white py-1 text-base shadow-lg ring-0 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          >
            {filteredData.length === 0 && query !== "" ? (
              <Combobox.Option
                value={{ id: null, name: query }}
                className="relative cursor-default select-none py-2 px-4 text-gray-700"
              >
                {query}
              </Combobox.Option>
            ) : (
              filteredData.map((item) => (
                <Combobox.Option
                  key={item?.id}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active
                        ? "bg-brand-primary100 text-white"
                        : "text-gray-900"
                    }`
                  }
                  value={item}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {item?.name}
                      </span>
                      {selected ? (
                        <span
                          className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                            active ? "text-white" : "text-brand-primary100"
                          }`}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
};

export { Autocomplete };
