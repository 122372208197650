import { useState } from "react";
import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";
import { sortBy, map, uniqWith, isEqual } from "lodash";
import { clsx } from "clsx";

import { Card } from "@/components/layout/Card";
import { H2, BodyText, Divider } from "@/components/typography";
import type {
  OfferOverviewStoryblok,
  CampsiteStoryblok,
} from "@/component-types-sb";

type OfferOverviewProps = {
  blok: OfferOverviewStoryblok;
};

const OfferOverview = ({ blok }: OfferOverviewProps) => {
  const [selectedCampsite, setSelectedCampsite] =
    useState<CampsiteStoryblok | null>(null);

  const campsites: CampsiteStoryblok[] = map(
    blok.offers,
    "content.campsites"
  ).flat();
  const uniqueCampsites = uniqWith(campsites, isEqual);
  const sortedCampsites = sortBy(uniqueCampsites, ["name"]);

  return (
    <article className="w-full" {...storyblokEditable(blok)}>
      <header>
        {blok.header?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </header>

      <section className="py-8 mx-2 lg:mx-0 text-center">
        <H2 colorScheme="primary">{blok.title}</H2>
        <BodyText className="max-w-md mx-auto mt-2 text-center">
          {blok.description}
        </BodyText>

        <Divider />

        <div className="max-w-5xl mx-auto mb-24 grid gap-8 text-left">
          <div className="snap-x scroll-px-4 snap-mandatory scroll-smooth flex md:flex-wrap justify-left md:justify-center gap-3 mx-4 mb-8 pb-4 md:pb-0 overflow-x-auto">
            <button
              className={clsx(
                "bg-white snap-start shrink-0 py-1 px-2 border text-sm hover:border-brand-primary100 hover:text-brand-primary100",
                {
                  ["border-brand-primary100 text-brand-primary100 font-medium"]:
                    !selectedCampsite,
                  ["border-gray-400"]: selectedCampsite,
                }
              )}
              onClick={() => {
                setSelectedCampsite(null);
              }}
            >
              Alle Campingparks
            </button>

            {sortedCampsites?.map((campsite, index: number) => (
              <button
                key={index}
                className={clsx(
                  "bg-white snap-start shrink-0 py-1 px-2 border text-sm hover:border-brand-primary100 hover:text-brand-primary100 shadow-sm",
                  {
                    ["border-brand-primary100 text-brand-primary100 font-medium"]:
                      campsite === selectedCampsite,
                    ["border-gray-400"]: campsite !== selectedCampsite,
                  }
                )}
                onClick={() => {
                  setSelectedCampsite(campsite);
                }}
              >
                {campsite.name}
              </button>
            ))}
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
            {blok?.offers
              ?.filter((offer: any) =>
                selectedCampsite
                  ? offer.content?.campsites
                      .map((campsite: CampsiteStoryblok) => campsite.uuid)
                      .includes(selectedCampsite?.uuid)
                  : true
              )
              .map((nestedBlok: any) => (
                <Card
                  key={nestedBlok.id}
                  image={nestedBlok.content?.image}
                  href={nestedBlok.full_slug}
                  name={nestedBlok.name}
                  text={nestedBlok.content?.subline}
                  buttonLabel="Mehr erfahren"
                />
              ))}
          </div>
        </div>
      </section>
    </article>
  );
};

export default OfferOverview;
