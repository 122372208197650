import Link from "next/link";
import { storyblokEditable } from "@storyblok/react/rsc";
import { renderRichText } from "@storyblok/react";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { clsx } from "clsx";

import { RichText } from "@/components/typography";
import { Button } from "@/components/layout/Button";
import type { AccordionItemStoryblok } from "@/component-types-sb";

type AccordionItemProps = {
  blok: AccordionItemStoryblok;
};

const AccordionItem = ({ blok }: AccordionItemProps) => (
  <Disclosure {...storyblokEditable(blok)}>
    {({ open }) => (
      <>
        <Disclosure.Button className="flex w-full justify-between py-2 text-left font-medium text-brand-primary100 focus:outline-none focus-visible:ring focus-visible:ring-brand-primary200 focus-visible:ring-opacity-75">
          <span className="text-lg font-semibold">{blok.name}</span>
          <ChevronDownIcon
            className={clsx("h-5 w-5 text-brand-primary100", {
              ["rotate-180 transform"]: open,
            })}
          />
        </Disclosure.Button>
        <Disclosure.Panel className="pt-1 pb-4 text-sm text-gray-500">
          <RichText html={renderRichText(blok.content)} />
          <Link href={blok.link?.url}>
            <Button arrow colorScheme="secondary" className="my-2">
              Mehr Infos
            </Button>
          </Link>
        </Disclosure.Panel>
      </>
    )}
  </Disclosure>
);

export default AccordionItem;
