import Link from "next/link";
import Image from "next/image";
import { storyblokEditable } from "@storyblok/react/rsc";
import { renderRichText } from "@storyblok/react";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { clsx } from "clsx";

import { H1, H3, RichText, BodyText } from "@/components/typography";
import { Breadcrumb } from "@/components/layout/Breadcrumb";
import type { OfferStoryblok } from "@/component-types-sb";
import imageLoader from "@/lib/utils/imageLoader";

type OfferProps = {
  blok: OfferStoryblok;
};

const Offer = ({ blok }: OfferProps) => (
  <article className="w-full" {...storyblokEditable(blok)}>
    <section className="relative max-w-6xl mx-auto grid grid-cols-3 gap-8 p-4">
      <div className="col-span-3 md:col-span-2 md:p-8">
        <header>
          <Breadcrumb
            items={[
              { href: "/angebote", title: "Angebote" },
              { title: blok.title },
            ]}
          />
          <H1 colorScheme="primary">{blok.title}</H1>
        </header>

        <RichText html={renderRichText(blok.content)} className="mt-6" />

        {blok.link?.cached_url && (
          <Link href={`/${blok.link.cached_url}`}>
            <div className="bg-white inline-flex snap-start shrink-0 py-1 px-2 my-4 border text-sm border-gray-400">
              {blok.label}
            </div>
          </Link>
        )}

        <BodyText className="border-gray-300 border-l-2 pl-2 italic font-medium leading-6 text-sm my-8">
          {blok.disclaimer}
        </BodyText>
      </div>

      <aside className="col-span-3 md:col-span-1 md:p-8">
        <Image
          className="object-cover w-full transition duration-500 aspect-[4/3]"
          loader={imageLoader}
          src={blok.image?.filename || ""}
          width={400}
          height={300}
          sizes="20vw"
          alt={blok.image?.alt || ""}
        />

        <H3 colorScheme="secondary">{blok.cta}</H3>

        {blok.campsites?.map((nestedBlok: any) => (
          <Disclosure key={nestedBlok.id}>
            {({ open }) => (
              <div className="border-t-2 border-gray-200">
                <Disclosure.Button className="flex w-full justify-between py-2 text-left font-medium text-brand-primary100 focus:outline-none focus-visible:ring focus-visible:ring-brand-primary200 focus-visible:ring-opacity-75">
                  <span className="text-lg font-semibold">
                    {nestedBlok.name}
                  </span>
                  <ChevronDownIcon
                    className={clsx("h-5 w-5 text-brand-primary100", {
                      ["rotate-180 transform"]: open,
                    })}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="pt-1 pb-4 text-sm text-gray-500">
                  <address className="not-italic leading-relaxed">
                    <a href={`tel:${nestedBlok.content?.telephone}`}>
                      {nestedBlok.content?.telephone}
                    </a>
                    <br />
                    <Link href={`/${nestedBlok.full_slug}`}>
                      Zum Campingpark
                    </Link>
                  </address>
                </Disclosure.Panel>
              </div>
            )}
          </Disclosure>
        ))}
      </aside>
    </section>
  </article>
);

export default Offer;
