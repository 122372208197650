import { storyblokEditable } from "@storyblok/react/rsc";

import { H2, BodyText, Divider } from "@/components/typography";
import type { CatalogStoryblok } from "@/component-types-sb";

type CatalogProps = {
  blok: CatalogStoryblok;
};

const Catalog = ({ blok }: CatalogProps) => {
  return (
    <article
      className="w-full p-4 md:p-24 text-center"
      {...storyblokEditable(blok)}
    >
      <header>
        <H2 as="h1" colorScheme="primary">
          {blok.title}
        </H2>
        <BodyText className="max-w-md mx-auto mt-2 text-center">
          {blok.description}
        </BodyText>

        <Divider />
      </header>

      <embed
        className="w-full h-screen"
        type="application/pdf"
        src={blok.files?.[0].filename}
      />

      <ul className="divide-y divide-gray-100 border border-gray-200 mt-16">
        {blok.files?.map((file) => (
          <li
            key={file.id}
            className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6"
          >
            <div className="flex w-full flex-1 items-center">
              <svg
                className="h-5 w-5 flex-shrink-0 text-gray-400"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M15.621 4.379a3 3 0 00-4.242 0l-7 7a3 3 0 004.241 4.243h.001l.497-.5a.75.75 0 011.064 1.057l-.498.501-.002.002a4.5 4.5 0 01-6.364-6.364l7-7a4.5 4.5 0 016.368 6.36l-3.455 3.553A2.625 2.625 0 119.52 9.52l3.45-3.451a.75.75 0 111.061 1.06l-3.45 3.451a1.125 1.125 0 001.587 1.595l3.454-3.553a3 3 0 000-4.242z"
                  clipRule="evenodd"
                />
              </svg>
              <div className="ml-4 flex min-w-0 flex-1 gap-2">
                <span className="font-medium">{file.title}</span>
                <span className="flex-shrink-0 text-gray-400">
                  {file.copyright}
                </span>
              </div>
            </div>
            <div className="ml-4 flex-shrink-0">
              <a
                href={file.filename}
                className="font-medium text-brand-secondary100 hover:text-brand-secondary100"
              >
                Download
              </a>
            </div>
          </li>
        ))}
      </ul>
    </article>
  );
};

export default Catalog;
