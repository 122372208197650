// helper for international date format, results from a provided string timestamp
export const formatDate = (
  date?: string | Date,
  locales: string = "de-DE",
  options: Intl.DateTimeFormatOptions = { dateStyle: "full" }
) => {
  if (!date) return "";
  date = typeof date === "string" ? new Date(date) : date;
  return new Intl.DateTimeFormat(locales, options).format(date);
};

export const formatDateTime = (dateString?: string, locales?: string) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  return new Intl.DateTimeFormat(locales, {
    dateStyle: "full",
    timeStyle: "short",
  }).format(date);
};

// helper for international price format
export const formatPrice = (
  number?: number | string,
  currency?: string,
  locale?: string,
  options?: Intl.NumberFormatOptions
) => {
  number = Number(number);
  locale = locale || "de-DE";
  currency = currency || "EUR";

  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
    currencyDisplay: "symbol",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...options,
  }).format(number);
};

export const formatGeoCoordinates = (decimalDegrees: number) => {
  // Split the decimal degrees into degrees, minutes, and seconds
  const degrees = Math.floor(decimalDegrees);
  const minutes = Math.floor((decimalDegrees - degrees) * 60);
  const seconds = ((decimalDegrees - degrees - minutes / 60) * 3600).toFixed(2);

  // Determine the direction (N for positive, S for negative)
  const direction = degrees >= 0 ? "N" : "S";

  // Ensure positive values for degrees, minutes, and seconds
  const absDegrees = Math.abs(degrees);
  const absMinutes = Math.abs(minutes);
  const absSeconds = Math.abs(parseFloat(seconds));

  // Format the result as a string
  const formatted = `${absDegrees}° ${absMinutes}' ${absSeconds}" ${direction}`;
  return formatted;
};
