import { storyblokEditable } from "@storyblok/react/rsc";
import { renderRichText } from "@storyblok/react";
import { parse, isAfter, isBefore } from "date-fns";

import { H4, RichText } from "@/components/typography";
import type { SpecialOpeningHoursStoryblok } from "@/component-types-sb";

type SpecialOpeningHoursProps = {
  blok: SpecialOpeningHoursStoryblok;
};

const SpecialOpeningHoursProps = ({ blok }: SpecialOpeningHoursProps) => {
  if (blok.start && blok.end) {
    const today = Date.now();
    const start = parse(blok.start, "yyyy-MM-dd HH:mm", today);
    const end = parse(blok.end, "yyyy-MM-dd HH:mm", today);

    if (!isAfter(today, start) || !isBefore(today, end)) {
      return null;
    }
  }

  return (
    <div className="col-span-full pt-4" {...storyblokEditable(blok)}>
      <header className="text-center">
        <H4
          as="h3"
          colorScheme="primary"
          fontWeight="semibold"
          fontSize="lg"
          className="mb-4"
        >
          {blok.title}
        </H4>

        <RichText html={renderRichText(blok.text)} />
      </header>
    </div>
  );
};

export default SpecialOpeningHoursProps;
