import { storyblokEditable } from "@storyblok/react/rsc";

import { Card } from "@/components/layout/Card";
import { H2, Divider } from "@/components/typography";
import type { CategoryStoryblok } from "@/component-types-sb";

type FeaturedCategoriesPops = {
  blok: {
    _uid: string;
    headline: string;
    categories: CategoryStoryblok[];
  };
};

const FeaturedCategories = ({ blok }: FeaturedCategoriesPops) => (
  <section
    className="py-8 mx-2 lg:mx-0 text-center"
    {...storyblokEditable(blok)}
  >
    <H2 colorScheme="primary">{blok.headline}</H2>

    <Divider />

    <div className="grid grid-cols-4 gap-3 mr-3">
      {blok.categories.map((nestedBlok) => (
        <Card
          image={nestedBlok.content?.image}
          href={nestedBlok.full_slug}
          name={nestedBlok.name}
          text={nestedBlok.content?.lead}
          key={nestedBlok.id}
          buttonLabel="Reiseziel entdecken"
        />
      ))}
    </div>
  </section>
);

FeaturedCategories.blockName = "featured_categories";

export default FeaturedCategories;
