import { storyblokEditable } from "@storyblok/react/rsc";
import { Card } from "@/components/layout/Card";
import { H2, BodyText, Divider } from "@/components/typography";
import type { CategoryOverviewStoryblok } from "@/component-types-sb";

type CategoryOverviewProps = {
  blok: CategoryOverviewStoryblok;
};

const CategoryOverview = ({ blok }: CategoryOverviewProps) => (
  <article className="w-full" {...storyblokEditable(blok)}>
    <section className="py-8 mx-2 lg:mx-4 text-center">
      <H2 as="h1" colorScheme="primary">
        {blok.title}
      </H2>
      <BodyText className="max-w-md mx-auto mt-2 text-center">
        {blok.description}
      </BodyText>

      <Divider />

      <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
        {blok.categories?.map((nestedBlok: any) => (
          <Card
            key={nestedBlok.id}
            image={nestedBlok.content?.image}
            href={nestedBlok.full_slug}
            name={nestedBlok.name}
            text={nestedBlok.content?.lead}
            buttonLabel="Parks entdecken"
          />
        ))}
      </div>
    </section>
  </article>
);

export default CategoryOverview;
