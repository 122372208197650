import dynamic from "next/dynamic";
import { storyblokEditable } from "@storyblok/react/rsc";
import { renderRichText } from "@storyblok/react";
import { clsx } from "clsx";

import { H4, RichText } from "@/components/typography";
import type { IconName } from "@/components/icons";
import type { TextListStoryblok } from "@/component-types-sb";

const Icon = dynamic(() =>
  import("@/components/icons/Icon").then((module) => module.Icon)
);

type TextListProps = {
  blok: TextListStoryblok;
};

const TextList = ({ blok }: TextListProps) => (
  <div className="text-left mb-4" {...storyblokEditable(blok)}>
    {blok.name && (
      <H4
        as="h3"
        colorScheme="primary"
        fontWeight="bold"
        fontSize="lg"
        className="mb-4"
      >
        {blok.name}
      </H4>
    )}

    {blok.icon && (
      <Icon name={blok.icon as IconName} className="w-12 h-12 mx-auto" />
    )}
    <RichText
      html={renderRichText(blok.content)}
      className={clsx({
        "prose-p:line-clamp-2": blok.truncate,
      })}
    />
  </div>
);

export default TextList;
