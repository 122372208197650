import Link from "next/link";
import { Fragment, useEffect, useState } from "react";
import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";
import { renderRichText } from "@storyblok/react";

import { Breadcrumb } from "@/components/layout/Breadcrumb";
import { Card } from "@/components/layout/Card";
import { H1, H3, RichText } from "@/components/typography";
import type { JobStoryblok, CampsiteStoryblok } from "@/component-types-sb";
import { getJobs } from "@/lib/storyblok/getJobs";

type JobProps = {
  blok: JobStoryblok & { campsite?: CampsiteStoryblok };
  draft: boolean;
};

const Job = ({ blok, draft }: JobProps) => {
  const [jobs, setJobs] = useState<JobStoryblok[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getJobs({
        filter_query: {
          campsite: { in: blok.campsite?.uuid },
          _uid: { not_in: blok._uid },
        },
        draft,
      });

      setJobs(data.stories);
    };
    fetchData();
  }, [blok._uid, blok.campsite?.uuid, draft]);

  return (
    <article className="w-full" {...storyblokEditable(blok)}>
      <section className="bg-white relative max-w-6xl mx-auto grid grid-cols-3 gap-8 p-4">
        <div className="col-span-3 md:col-span-2 md:p-8">
          <header>
            <Breadcrumb
              items={[
                { href: "/karriere", title: "Karriere" },
                { href: "/jobs", title: "Stellenanzeigen" },
                { title: blok.title },
              ]}
            />
            <H1 colorScheme="primary">{blok.title}</H1>
            <H3 as="h2">{blok.subline}</H3>
          </header>

          <RichText html={renderRichText(blok.description)} />

          <div className="col-span-3 py-4">
            {blok.body?.map((nestedBlok: any) => (
              <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
          </div>
        </div>
        <aside className="col-span-3 md:col-span-1 md:p-8">
          <Card
            key={blok.campsite?.id}
            image={blok.campsite?.content?.image}
            href={blok.campsite?.full_slug}
            name={blok.campsite?.name}
            text={blok.campsite?.content?.lead}
            buttonLabel="Entdecken"
          />
          {jobs?.length > 0 && (
            <Fragment>
              <H3 colorScheme="primary">
                Andere Stellenanzeigen {blok.campsite?.name}
              </H3>
              <ul className="divide-y divide-gray-200">
                {jobs.map((nestedBlok) => (
                  <li key={nestedBlok.id} className="py-2">
                    <Link href={["/", nestedBlok.full_slug].join("")}>
                      {nestedBlok.content?.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </Fragment>
          )}
        </aside>
      </section>

      {blok.footer?.map((nestedBlok: any) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
    </article>
  );
};

export default Job;
