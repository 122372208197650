import { storyblokEditable } from "@storyblok/react/rsc";
import { Card } from "@/components/layout/Card";
import { H2, BodyText, Divider } from "@/components/typography";
import type { AccommodationOverviewStoryblok } from "@/component-types-sb";

type AccommodationOverviewProps = {
  blok: AccommodationOverviewStoryblok;
};

const AccommodationOverview = ({ blok }: AccommodationOverviewProps) => (
  <article className="flex flex-col items-center" {...storyblokEditable(blok)}>
    <section className="py-8 mx-2 lg:mx-0 text-center">
      <H2 as="h1" colorScheme="primary">
        {blok.title}
      </H2>
      <BodyText className="max-w-md mx-auto mt-2 text-center">
        {blok.description}
      </BodyText>

      <Divider />

      <div className="grid grid-cols-1 md:grid-cols-3 gap-3 mt-8 mx-2">
        {blok?.accommodations?.map((nestedBlok: any) => (
          <Card
            key={nestedBlok.id}
            image={nestedBlok.content?.images?.[0]}
            href={nestedBlok.full_slug}
            name={nestedBlok.name}
            buttonLabel="Entdecken"
          />
        ))}
      </div>
    </section>
  </article>
);

export default AccommodationOverview;
