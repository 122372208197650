import React from "react";

const Skeleton = ({ number = 1 }) => (
  <div role="status">
    {Array(number)
      .fill(0)
      .map((_, index) => (
        <div key={index}>
          <div className="flex flex-row w-full items-center gap-8 mb-4">
            <div className="bg-gray-200 w-24 h-24 aspect-square animate-pulse"></div>
            <div className="flex flex-col gap-2 w-9/12">
              <span className="w-2/12 bg-gray-200 h-2 rounded-full animate-pulse"></span>
              <span className="w-2/12 bg-gray-200 h-2 rounded-full animate-pulse"></span>
              <span className="w-12/12 bg-gray-200 h-2 rounded-full animate-pulse"></span>
              <span className="w-12/12 bg-gray-200 h-2 rounded-full animate-pulse"></span>
            </div>
          </div>
        </div>
      ))}
    <span className="sr-only">Wird geladen...</span>
  </div>
);

const FilterSkeleton = ({ number = 1 }) => {
  return Array(number)
    .fill(0)
    .map((_, index) => (
      <div
        key={index}
        role="status"
        className="w-1/12 h-8 bg-gray-200 animate-pulse"
      ></div>
    ));
};

export { Skeleton, FilterSkeleton };
