import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";

import { H2, BodyText, Divider } from "@/components/typography";
import type { ExperiencesStoryblok } from "@/component-types-sb";

type ExperiencesProps = {
  blok: ExperiencesStoryblok;
};

const Experiences = ({ blok }: ExperiencesProps) => (
  <article className="w-full" {...storyblokEditable(blok)}>
    {blok.links?.map((nestedBlok) => (
      <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
    ))}

    <section className="py-8 mx-2 lg:mx-2 text-center">
      <H2 as="h1" colorScheme="primary">
        {blok.title}
      </H2>

      <BodyText className="max-w-md mx-auto mt-2 text-center">
        {blok.description}
      </BodyText>

      <Divider />

      <div className="grid grid-cols-1">
        {blok.sections?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </div>
    </section>
  </article>
);

export default Experiences;
