import { getStoryblokApi, ISbStoriesParams } from "@storyblok/react/rsc";
import type {
  JobStoryblok,
  CampsiteStoryblok,
  OfficeStoryblok,
} from "@/component-types-sb";

type Request = {
  per_page?: number;
  filter_query?: {};
  draft?: boolean;
};

type Response = {
  data: {
    stories: JobStoryblok[];
    rels: CampsiteStoryblok[] | OfficeStoryblok[];
  };
};

const getJobs = async ({
  per_page = 50,
  filter_query = {},
  draft = false,
}: Request): Promise<Response> => {
  const preview = process.env.NEXT_PUBLIC_VERCEL_ENV === "preview" || draft;

  let sbParams: ISbStoriesParams = {
    version: preview ? "draft" : "published",
    cv: preview ? Date.now() : undefined,
    content_type: "job",
    sort_by: "name:asc",
    is_startpage: false,
    resolve_relations: "job.campsite",
    per_page,
    filter_query,
  };

  const storyblokApi = getStoryblokApi();
  return storyblokApi.get(`cdn/stories`, sbParams);
};

export { getJobs };
