import Image from "next/image";
import Link from "next/link";
import { format, parse } from "date-fns";
import { de } from "date-fns/locale";

import { H3, H4 } from "@/components/typography";
import type { NewsStoryblok } from "@/component-types-sb";

type NewsListProps = {
  headline: string;
  stories: NewsStoryblok[];
};

const NewsList = ({ headline, stories }: NewsListProps) => (
  <div className="relative pl-8 sm:pl-32 py-6 group">
    <H3 as="h2" colorScheme="secondary" className="mb-8">
      {headline}
    </H3>

    {stories.map((blok) => (
      <div
        key={blok.id}
        className="flex flex-col sm:flex-row items-start mb-4 group-last:before:hidden before:absolute before:left-2 sm:before:left-0 before:h-full before:px-px before:bg-gray-200 sm:before:ml-[6.5rem] before:self-start before:-translate-x-1/2 before:translate-y-3 after:absolute after:left-2 sm:after:left-0 after:w-2 after:h-2 after:bg-brand-primary100 after:border-4 after:box-content after:border-gray-50 after:rounded-full sm:after:ml-[6.5rem] after:-translate-x-1/2 after:translate-y-1.5"
      >
        <time className="sm:absolute left-0 translate-y-0.5 inline-flex items-center justify-center text-xs font-semibold uppercase w-20 h-6 mb-3 sm:mb-0 text-white bg-brand-secondary100 rounded-full">
          {format(
            parse(blok.content?.date, "yyyy-MM-dd HH:mm", new Date()),
            "d. LLL",
            { locale: de }
          )}
        </time>

        <Link
          className="w-full flex flex-wrap md:flex-nowrap min-w-0 gap-x-4"
          aria-label="Zur Nachricht"
          href={blok.full_slug}
        >
          <div className="min-w-0 flex-auto p-4">
            <div className="font-semibold text-gray-900">
              {blok.content?.campsite?.name}
            </div>
            <H4 as="h3" fontSize="2xl">
              {blok.name}
            </H4>
            <div className="font-light text-gray-900 py-2">
              {format(
                parse(blok.content?.date, "yyyy-MM-dd HH:mm", new Date()),
                "PPPP",
                { locale: de }
              )}
            </div>

            <p className="text-gray-800 line-clamp-3 my-4">
              {blok.content?.abstract}
            </p>
          </div>
          <div className="w-full p-4 md:w-52 md:h-52 flex-none">
            {blok.content?.images?.[0] && (
              <Image
                className="w-full h-full object-cover aspect-square"
                src={
                  blok.content.images[0].filename ||
                  blok.content.campsite?.content?.image?.filename
                }
                width={208}
                height={208}
                sizes="(max-width: 1024px) 100vw, 25vw"
                priority={false}
                alt={blok.content.images[0].alt || ""}
              />
            )}
          </div>
        </Link>
      </div>
    ))}
  </div>
);

export { NewsList };
