import { storyblokEditable } from "@storyblok/react/rsc";
import { Card } from "@/components/layout/Card";
import { H2, Divider, BodyText } from "@/components/typography";
import type { FeaturedAccommodationsStoryblok } from "@/component-types-sb";

type FeaturedAccommodationsPops = {
  blok: FeaturedAccommodationsStoryblok;
};

const FeaturedAccommodations = ({ blok }: FeaturedAccommodationsPops) => (
  <section
    className="py-8 mx-2 lg:mx-4 text-center"
    {...storyblokEditable(blok)}
  >
    <H2 colorScheme="primary">{blok.headline}</H2>
    <BodyText className="max-w-2xl mx-auto mt-4 text-center">
      {blok.text}
    </BodyText>

    <Divider />

    <div className="grid grid-cols-1 md:grid-cols-4 gap-3">
      {blok.accommodations?.map((nestedBlok: any) => (
        <Card
          key={nestedBlok.id}
          image={nestedBlok.content?.images?.[0]}
          href={nestedBlok.full_slug}
          name={nestedBlok.name}
          buttonLabel="Entdecken"
        />
      ))}
    </div>
  </section>
);

FeaturedAccommodations.blockName = "featured_accommodations";

export default FeaturedAccommodations;
