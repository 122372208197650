import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";
import { Tab } from "@headlessui/react";
import { H4 } from "@/components/typography";
import { clsx } from "clsx";
import type { TabsStoryblok } from "@/component-types-sb";

type TabsPops = {
  blok: TabsStoryblok;
};

const Tabs = ({ blok }: TabsPops) => (
  <div className="py-4" {...storyblokEditable(blok)}>
    {blok.headline && (
      <H4 colorScheme="secondary" fontWeight="bold" className="pb-2">
        {blok.headline}
      </H4>
    )}

    <div className="w-full px-2 pb-4 sm:px-0 border-2 border-gray-100">
      <Tab.Group>
        <Tab.List className="flex space-x-1 bg-gray-100 p-1">
          {blok.items?.map((nestedBlok) => (
            <Tab
              key={nestedBlok.title}
              className={({ selected }) =>
                clsx(
                  "w-full py-2.5 text-sm font-medium leading-5 text-brand-primary100 now",
                  "ring-white ring-opacity-60 ring-offset-2 ring-offset-brand-primary100 focus:outline-none focus:ring-2",
                  selected
                    ? "bg-white shadow-sm"
                    : "text-brand-primary100 hover:bg-white/[0.12]"
                )
              }
            >
              {nestedBlok.title}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-2">
          {blok.items?.map((nestedBlok) => (
            <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  </div>
);

export default Tabs;
