import { useEffect, useState } from "react";
import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";
import { sortBy, uniqWith, isEqual, compact } from "lodash";
import { clsx } from "clsx";

import { NewsList } from "@/components/layout/NewsList";
import { groupByMonth } from "@/lib/utils/groupByMonth";
import { H2, BodyText, Divider } from "@/components/typography";
import type {
  NewsOverviewStoryblok,
  NewsStoryblok,
  CampsiteStoryblok,
} from "@/component-types-sb";

type NewsOverviewProps = {
  blok: NewsOverviewStoryblok;
  news: NewsStoryblok[];
};

const NewsOverview = ({ blok, news }: NewsOverviewProps) => {
  const [results, setResults] = useState<NewsStoryblok[]>(news);
  const [selectedCampsite, setSelectedCampsite] =
    useState<CampsiteStoryblok | null>(null);

  useEffect(() => {
    if (selectedCampsite) {
      const filtered = news.filter(
        ({ content }) => content?.campsite.uuid === selectedCampsite?.uuid
      );
      setResults(filtered);
    } else {
      setResults(news);
    }
  }, [news, selectedCampsite]);

  const sorted = sortBy(results, ["content.date"]).reverse();
  const storiesByMonth = Object.entries(groupByMonth(sorted));

  const campsites: CampsiteStoryblok[] = compact(
    uniqWith(
      news.map(({ content }) => content?.campsite),
      isEqual
    )
  );

  return (
    <article className="w-full" {...storyblokEditable(blok)}>
      <header>
        {blok.header?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok.uuid} />
        ))}
      </header>

      <section className="py-8 text-center">
        <H2 as="h1" colorScheme="primary">
          {blok.title}
        </H2>
        <BodyText className="max-w-md mx-auto mt-2 text-center">
          {blok.description}
        </BodyText>

        <Divider />

        <div className="max-w-5xl mx-auto mb-24 grid gap-8 text-left">
          <div className="snap-x scroll-px-4 snap-mandatory scroll-smooth flex md:flex-wrap justify-left md:justify-center gap-3 mx-4 mb-8 pb-4 md:pb-0 overflow-x-auto">
            <button
              className={clsx(
                "bg-white snap-start shrink-0 py-1 px-2 border text-sm hover:border-brand-primary100 hover:text-brand-primary100",
                {
                  ["border-brand-primary100 text-brand-primary100 font-medium"]:
                    !selectedCampsite,
                  ["border-gray-400"]: selectedCampsite,
                }
              )}
              onClick={() => {
                setSelectedCampsite(null);
              }}
            >
              Alle Campingparks
            </button>

            {campsites?.map((campsite, index: number) => (
              <button
                key={index}
                className={clsx(
                  "bg-white snap-start shrink-0 py-1 px-2 border text-sm hover:border-brand-primary100 hover:text-brand-primary100 shadow-sm",
                  {
                    ["border-brand-primary100 text-brand-primary100 font-medium"]:
                      campsite === selectedCampsite,
                    ["border-gray-400"]: campsite !== selectedCampsite,
                  }
                )}
                onClick={() => {
                  setSelectedCampsite(campsite);
                }}
              >
                {campsite.name}
              </button>
            ))}
          </div>

          <div className="-my-6 overflow-hidden">
            {storiesByMonth.map(([month, stories]) => (
              <NewsList key={month} headline={month} stories={stories} />
            ))}
          </div>
        </div>
      </section>
    </article>
  );
};

export default NewsOverview;
