import { storyblokEditable } from "@storyblok/react/rsc";
import { renderRichText } from "@storyblok/react";

import { H4, RichText } from "@/components/typography";
import type { BoxStoryblok } from "@/component-types-sb";

type BoxProps = {
  blok: BoxStoryblok;
};

const Box = ({ blok }: BoxProps) => (
  <div
    className="mb-2 pb-4 border border-brand-primary100"
    {...storyblokEditable(blok)}
  >
    <div className="bg-brand-primary100 p-1 mb-4 ">
      <H4
        as="h3"
        colorScheme="white"
        fontWeight="bold"
        fontSize="lg"
        className="uppercase"
      >
        {blok.headline}
      </H4>
    </div>
    <RichText html={renderRichText(blok.content)} />
  </div>
);

export default Box;
