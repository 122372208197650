"use client";

import { useState, useEffect } from "react";
import type { CampsiteStoryblok } from "@/component-types-sb";
import { clsx } from "clsx";

import { FilterSkeleton } from "@/components/layout/Skeleton";

type FilterProps = {
  onSelect?: (value: CampsiteStoryblok | null) => void;
  type?: "events" | "news" | "faq" | "offers";
};

const Filter = ({ onSelect, type }: FilterProps) => {
  const [campsites, setCampsites] = useState<CampsiteStoryblok[]>([]);
  const [selectedCampsite, setSelectedCampsite] =
    useState<CampsiteStoryblok | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch("/api/campsites");
      const json = await res.json();
      const data = type
        ? json.filter((campsite: CampsiteStoryblok) => campsite[type] > 0)
        : json;
      setCampsites(data);
      setIsLoading(false);
    };
    fetchData();
  }, [type]);

  const handleClick = (value: CampsiteStoryblok | null) => {
    setSelectedCampsite(value);
    onSelect?.(value);
  };

  return (
    <div className="snap-x scroll-px-4 snap-mandatory scroll-smooth flex md:flex-wrap justify-left md:justify-center gap-3 mx-4 mb-8 pb-4 md:pb-0 overflow-x-auto">
      <button
        className={clsx(
          "bg-white snap-start shrink-0 py-1 px-2 border text-sm hover:border-brand-primary100 hover:text-brand-primary100",
          {
            ["border-brand-primary100 text-brand-primary100 font-medium"]:
              !selectedCampsite,
            ["border-gray-400"]: selectedCampsite,
          }
        )}
        onClick={() => {
          handleClick(null);
        }}
      >
        Alle Campingparks
      </button>

      {isLoading && <FilterSkeleton number={4} />}

      {campsites?.map((campsite, index: number) => (
        <button
          key={index}
          className={clsx(
            "bg-white snap-start shrink-0 py-1 px-2 border text-sm hover:border-brand-primary100 hover:text-brand-primary100 shadow-sm",
            {
              ["border-brand-primary100 text-brand-primary100 font-medium"]:
                campsite === selectedCampsite,
              ["border-gray-400"]: campsite !== selectedCampsite,
            }
          )}
          onClick={() => {
            handleClick(campsite);
          }}
        >
          {campsite.name}
        </button>
      ))}
    </div>
  );
};

export { Filter };
