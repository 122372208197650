import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";
import { H2, BodyText } from "@/components/typography";
import type { GridStoryblok } from "@/component-types-sb";

type GridPops = {
  blok: GridStoryblok;
};

const Grid = ({ blok }: GridPops) => {
  return (
    <section
      className="py-8 px-4 lg:px-0 text-center"
      {...storyblokEditable(blok)}
    >
      <H2 colorScheme="primary">{blok.headline}</H2>
      <BodyText className="max-w-md mx-auto mt-4 text-center">
        {blok.text}
      </BodyText>

      <div className="max-w-5xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-12 py-8">
        {blok.columns?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </div>
    </section>
  );
};

export default Grid;
