import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";
import { H4 } from "@/components/typography";
import type {
  ListStoryblok,
  ListItemStoryblok,
  CampsiteStoryblok,
} from "@/component-types-sb";

type ListPops = {
  blok: ListStoryblok;
  campsite?: CampsiteStoryblok;
};

const List = ({ blok, campsite }: ListPops) => {
  const items = campsite
    ? blok.items?.filter((item) => item.campsite === campsite._uid)
    : blok.items?.filter((item) => !item.campsite);

  if (!items?.length) {
    return null;
  }

  return (
    <div className="p-4 bg-white" {...storyblokEditable(blok)}>
      <H4 colorScheme="secondary" fontWeight="bold" className="pb-2">
        {blok.headline}
      </H4>

      <div className="w-full">
        {items?.map((nestedBlok: ListItemStoryblok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </div>
    </div>
  );
};

export default List;
