import { clsx } from "clsx";
import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";
import { H2, Divider, BodyText } from "@/components/typography";
import type { TextListBoxesStoryblok } from "@/component-types-sb";

type TextListBoxesPops = {
  blok: TextListBoxesStoryblok;
};

const TextListBoxes = ({ blok }: TextListBoxesPops) => (
  <section className="py-8 text-center" {...storyblokEditable(blok)}>
    <H2 colorScheme="primary" className="break-words md:break-normal">
      {blok.headline}
    </H2>
    <BodyText className="max-w-2xl mx-auto mt-4 text-center">
      {blok.text}
    </BodyText>

    <Divider />

    <div
      className={clsx("py-8", {
        ["bg-gray-200"]:
          blok.style === "background" || blok.style === undefined,
        ["border-2 border-brand-primary100"]: blok.style === "border",
      })}
    >
      <div className="max-w-5xl mx-auto grid grid-cols-1 px-8 md:px-0 md:grid-cols-3 gap-12">
        {blok.items?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </div>
    </div>
  </section>
);

export default TextListBoxes;
