import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";
import { renderRichText } from "@storyblok/react";

import { H1, H3, RichText } from "@/components/typography";
import type { OfficeStoryblok } from "@/component-types-sb";

type OfficeProps = {
  blok: OfficeStoryblok;
};

const Office = ({ blok }: OfficeProps) => (
  <article className="w-full" {...storyblokEditable(blok)}>
    <header>
      {blok.header?.map((nestedBlok) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
    </header>

    <section className="bg-white relative max-w-6xl mx-auto grid grid-cols-3 gap-8 p-4">
      <div className="col-span-3 md:col-span-2 md:p-8">
        <H1 colorScheme="primary">{blok.name}</H1>

        <H3 as="h2">{blok.lead}</H3>

        <p className="py-4">{blok.description}</p>
      </div>
      <address className="col-span-3 md:col-span-1 md:p-8 not-italic">
        <RichText html={renderRichText(blok.address)} />
      </address>
    </section>
  </article>
);

export default Office;
