import { ReactNode } from "react";
import Image from "next/image";
import type {
  AwardItemStoryblok,
  MultilinkStoryblok,
} from "@/component-types-sb";

type LinkWrapperProps = {
  link?: MultilinkStoryblok;
  children: ReactNode;
};

type AwardItemProps = {
  blok: AwardItemStoryblok;
};

const LinkWrapper = ({ link, children }: LinkWrapperProps) =>
  link?.url && link?.url !== "" ? (
    <a href={link?.url} rel="noopener" target="_blank">
      {children}
    </a>
  ) : (
    <div>{children}</div>
  );

const AwardItem = ({ blok }: AwardItemProps) => (
  <LinkWrapper link={blok.link}>
    <Image
      className="object-contain w-full h-36"
      src={blok.image?.filename || ""}
      width={200}
      height={200}
      alt={blok.image?.alt || blok.title || ""}
      title={blok.image?.title}
    />
    <figcaption className="text-gray-500 m-2 text-xs max-w-[200px]">
      {blok.description}
    </figcaption>
  </LinkWrapper>
);

export default AwardItem;
