"use client";

// @see https://flowbite.com/docs/components/bottom-navigation/#video-player-bar
// @see https://github.com/Ibaslogic/react-audio-player

import Link from "next/link";
import Image from "next/image";
import { useEffect, useRef, useState } from "react";
import { clsx } from "clsx";

const formatTime = (time: number) => {
  if (time && !isNaN(time)) {
    const minutes = Math.floor(time / 60);
    const formatMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const seconds = Math.floor(time % 60);
    const formatSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${formatMinutes}:${formatSeconds}`;
  }
  return "00:00";
};

const MusicPlayer = () => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(60);
  const [muteVolume, setMuteVolume] = useState(false);
  const [duration, setDuration] = useState(0);
  const [percent, setPercent] = useState(0);

  const togglePlayPause = () => {
    setIsPlaying((prev) => !prev);
  };

  const expand = () => {
    if (!isExpanded) {
      setIsPlaying(true);
    }
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (isPlaying) {
      audioRef.current?.play();
    } else {
      audioRef.current?.pause();
    }
  }, [isPlaying, audioRef]);

  const skipForward = () => {
    if (audioRef.current) {
      audioRef.current.currentTime += 15;
    }
  };

  const skipBackward = () => {
    if (audioRef.current) {
      audioRef.current.currentTime -= 15;
    }
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume / 100;
      audioRef.current.muted = muteVolume;
    }
  }, [volume, audioRef, muteVolume]);

  const onLoadedMetadata = () => {
    setDuration(audioRef.current?.duration || 0);
  };

  const onTimeUpdate = (e: any) => {
    const time = parseInt(e.target.currentTime);
    const duration = audioRef.current?.duration || 0;
    const value = Math.floor((100 / duration) * time);
    setPercent(value);
  };

  const onEnded = (e: any) => {
    setIsPlaying(false);
    setDuration(0);
  };

  const PlayButton = () => (
    <button
      data-tooltip-target="tooltip-pause"
      type="button"
      className="inline-flex items-center justify-center p-2.5 mx-2 font-medium bg-brand-primary100 rounded-full hover:bg-brand-primary200 group focus:ring-4 focus:ring-brand-primary50 focus:outline-none"
      onClick={togglePlayPause}
    >
      {isPlaying ? (
        <>
          <svg
            className="w-3 h-3 text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 10 16"
          >
            <path
              fillRule="evenodd"
              d="M0 .8C0 .358.32 0 .714 0h1.429c.394 0 .714.358.714.8v14.4c0 .442-.32.8-.714.8H.714a.678.678 0 0 1-.505-.234A.851.851 0 0 1 0 15.2V.8Zm7.143 0c0-.442.32-.8.714-.8h1.429c.19 0 .37.084.505.234.134.15.209.354.209.566v14.4c0 .442-.32.8-.714.8H7.857c-.394 0-.714-.358-.714-.8V.8Z"
              clipRule="evenodd"
            />
          </svg>
          <span className="sr-only">Pause</span>
        </>
      ) : (
        <>
          <svg
            className="w-3 h-3 text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 10 16"
          >
            <path
              fillRule="evenodd"
              d="M0 .984v14.032a1 1 0 0 0 1.506.845l12.006-7.016a.974.974 0 0 0 0-1.69L1.506.139A1 1 0 0 0 0 .984Z"
              clipRule="evenodd"
            />
          </svg>
          <span className="sr-only">Play</span>
        </>
      )}
    </button>
  );

  return (
    <div
      className={clsx(
        "fixed bottom-0 right-0 z-50 grid grid-cols-1 transition-all ease-out duration-300 drop-shadow-md bg-white md:grid-cols-3",
        {
          ["w-full h-24 px-8"]: isExpanded,
          ["hidden md:flex h-12"]: !isExpanded,
        }
      )}
    >
      <div className="items-center justify-center col-span-1 hidden mr-auto text-gray-500 md:flex cursor-pointer">
        {!isExpanded && <PlayButton />}
        <Link href="/song">
          <Image
            className={clsx("transition-all ease-out duration-300", {
              ["w-20 h-20"]: isExpanded,
              ["w-0 h-0"]: !isExpanded,
            })}
            src="/knaus_song_cover.webp"
            width={80}
            height={80}
            alt="Der KNAUS Campingparks gute Laune Schlager Hit"
          />
        </Link>
        <div
          className={clsx("ml-3 text-sm flex gap-1", {
            ["flex-col"]: isExpanded,
            ["flex-row items-center"]: !isExpanded,
          })}
          onClick={expand}
        >
          <span className="font-normal">
            Knaus Campingparks feat. Florian Timm
          </span>
          <span
            className={clsx("font-bold pr-4", {
              ["text-lg"]: isExpanded,
            })}
          >
            Fühl Dich frei
          </span>
        </div>
      </div>
      <div
        className={clsx("items-center w-full flex", {
          ["flex"]: isExpanded,
          ["hidden"]: !isExpanded,
        })}
      >
        <div className="w-full">
          <div className="flex items-center justify-center mx-auto mb-1">
            <audio
              src="/knaus_song.mp3"
              controls={false}
              ref={audioRef}
              preload="none"
              onLoadedMetadata={onLoadedMetadata}
              onTimeUpdate={onTimeUpdate}
              onEnded={onEnded}
            />
            <button
              data-tooltip-target="tooltip-previous"
              type="button"
              className="p-2.5 group rounded-full hover:bg-gray-100 focus:outline-none focus:ring-4 focus:ring-gray-200"
              onClick={skipForward}
            >
              <svg
                className="w-4 h-4 text-gray-500 group-hover:text-gray-900"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 12 16"
              >
                <path d="M10.819.4a1.974 1.974 0 0 0-2.147.33l-6.5 5.773A2.014 2.014 0 0 0 2 6.7V1a1 1 0 0 0-2 0v14a1 1 0 1 0 2 0V9.3c.055.068.114.133.177.194l6.5 5.773a1.982 1.982 0 0 0 2.147.33A1.977 1.977 0 0 0 12 13.773V2.227A1.977 1.977 0 0 0 10.819.4Z" />
              </svg>
              <span className="sr-only">Zurück</span>
            </button>
            <div
              id="tooltip-previous"
              role="tooltip"
              className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip"
            >
              Zurück
              <div className="tooltip-arrow" data-popper-arrow></div>
            </div>
            <PlayButton />
            <div
              id="tooltip-pause"
              role="tooltip"
              className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip"
            >
              Pause
              <div className="tooltip-arrow" data-popper-arrow></div>
            </div>
            <button
              data-tooltip-target="tooltip-next"
              type="button"
              className="p-2.5 group rounded-full hover:bg-gray-100 mr-1 focus:outline-none focus:ring-4 focus:ring-gray-200"
            >
              <svg
                className="w-4 h-4 text-gray-500 group-hover:text-gray-900"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 12 16"
              >
                <path d="M11 0a1 1 0 0 0-1 1v5.7a2.028 2.028 0 0 0-.177-.194L3.33.732A2 2 0 0 0 0 2.227v11.546A1.977 1.977 0 0 0 1.181 15.6a1.982 1.982 0 0 0 2.147-.33l6.5-5.773A1.88 1.88 0 0 0 10 9.3V15a1 1 0 1 0 2 0V1a1 1 0 0 0-1-1Z" />
              </svg>
              <span className="sr-only">Weiter</span>
            </button>
            <div
              id="tooltip-next"
              role="tooltip"
              className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip"
              onClick={skipBackward}
            >
              Weiter
              <div className="tooltip-arrow" data-popper-arrow></div>
            </div>
          </div>
          <div className="flex items-center justify-between space-x-2">
            <span className="text-sm font-medium text-gray-500">0:00</span>
            <div className="w-full bg-gray-200 rounded-full h-1.5">
              <div
                className="bg-brand-primary100 h-1.5 rounded-full"
                style={{ width: `${percent}%` }}
              ></div>
            </div>
            <span className="text-sm font-medium text-gray-500">
              {formatTime(duration)}
            </span>
          </div>
        </div>
      </div>
      <div
        className={clsx("items-center justify-center hidden ml-auto", {
          ["md:flex"]: isExpanded,
          ["hidden"]: !isExpanded,
        })}
      >
        <button
          data-tooltip-target="tooltip-volume"
          type="button"
          className="p-2.5 group rounded-full hover:bg-gray-100 focus:outline-none focus:ring-4 focus:ring-gray-200"
          onClick={() => setMuteVolume((prev) => !prev)}
        >
          <svg
            className="w-4 h-4 text-gray-500 group-hover:text-gray-900"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 18"
          >
            <path d="M10.836.357a1.978 1.978 0 0 0-2.138.3L3.63 5H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h1.63l5.07 4.344a1.985 1.985 0 0 0 2.142.299A1.98 1.98 0 0 0 12 15.826V2.174A1.98 1.98 0 0 0 10.836.357Zm2.728 4.695a1.001 1.001 0 0 0-.29 1.385 4.887 4.887 0 0 1 0 5.126 1 1 0 0 0 1.674 1.095A6.645 6.645 0 0 0 16 9a6.65 6.65 0 0 0-1.052-3.658 1 1 0 0 0-1.384-.29Zm4.441-2.904a1 1 0 0 0-1.664 1.11A10.429 10.429 0 0 1 18 9a10.465 10.465 0 0 1-1.614 5.675 1 1 0 1 0 1.674 1.095A12.325 12.325 0 0 0 20 9a12.457 12.457 0 0 0-1.995-6.852Z" />
          </svg>
          <span className="sr-only">Lautstärke anpassen</span>
        </button>
        <div
          id="tooltip-volume"
          role="tooltip"
          className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip"
        >
          Lautstärke anpassen
          <div className="tooltip-arrow" data-popper-arrow></div>
        </div>
        <button
          data-tooltip-target="tooltip-minimize"
          type="button"
          className="p-2.5 group rounded-full hover:bg-gray-100 mr-1 focus:outline-none focus:ring-4 focus:ring-gray-200"
          onClick={expand}
        >
          <svg
            className="w-4 h-4 text-gray-500 group-hover:text-gray-900"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 100 100"
          >
            <path
              stroke="none"
              d="M 59.900002 49.099998 L 84.900002 24.199997 C 86.300003 22.800003 87 20.900002 87 19 C 87 17.199997 86.199997 15.5 84.900002 14.300003 C 83.5 12.900002 81.699997 12.199997 79.800003 12.199997 C 78 12.199997 76.300003 13 75.099998 14.300003 L 50.099998 39.199997 L 24.9 14.099998 C 23.5 12.699997 21.6 12 19.799999 12 C 18 12 16.299999 12.800003 15.1 14.099998 C 13.7 15.5 13 17.400002 13 19.300003 C 13 21.099998 13.8 22.800003 15.1 24 L 40.099998 48.900002 L 15.1 74 C 13.7 75.400002 13 77.300003 13 79.199997 C 13 81 13.8 82.699997 15.1 83.900002 C 16.4 85.199997 18.299999 86 20 86 C 21.9 86 23.5 85.300003 24.9 83.900002 L 50.099998 59 L 75.099998 83.900002 C 76.400002 85.199997 78.300003 86 80 86 C 81.900002 86 83.5 85.300003 84.900002 83.900002 C 86.300003 82.5 87 80.599998 87 78.699997 C 87 76.900002 86.199997 75.199997 84.900002 74 L 59.900002 49.099998 Z"
            />
          </svg>
          <span className="sr-only">Verkleinern</span>
        </button>
        <div
          id="tooltip-buy"
          role="tooltip"
          className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip"
        >
          Verkleinern
          <div className="tooltip-arrow" data-popper-arrow></div>
        </div>
      </div>
    </div>
  );
};

export { MusicPlayer };
