import Image from "next/image";
import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";

import { H2, BodyText, Divider } from "@/components/typography";
import type { ExperienceStoryblok } from "@/component-types-sb";

type ExperienceProps = {
  blok: ExperienceStoryblok;
};

const Experience = ({ blok }: ExperienceProps) => {
  return (
    <article className="w-full" {...storyblokEditable(blok)}>
      <header>
        {blok.header?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </header>

      <section className="py-8 text-center">
        <div className="max-w-5xl mx-auto mb-24 grid gap-8 text-left">
          {blok.body?.map((nestedBlok) => (
            <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
          ))}
        </div>
      </section>
    </article>
  );
};

export default Experience;
