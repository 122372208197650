import type { ComponentPropsWithoutRef, ElementType } from "react";
import { clsx } from "clsx";
import type { ColorScheme, FontSize } from "@/lib/types";

type Props<TElementType extends ElementType> =
  ComponentPropsWithoutRef<TElementType> & {
    colorScheme?: ColorScheme;
    fontSize?: FontSize;
    as?: Extract<TElementType, "h1" | "h2" | "h3" | "h4">;
  };

const H3 = <TElementType extends ElementType>({
  children,
  colorScheme = "secondary",
  fontSize = "3xl",
  as,
  ...rest
}: Props<TElementType>) => {
  const Headline = as ?? "h3";

  return (
    <Headline
      className={clsx(`text-${fontSize} font-regular my-4`, rest.className, {
        ["text-brand-primary100"]: colorScheme === "primary",
        ["text-brand-secondary100"]: colorScheme === "secondary",
        ["text-white"]: colorScheme === "white",
      })}
    >
      {children}
    </Headline>
  );
};

export { H3 };
