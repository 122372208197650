export const groupByMonth = <T extends Record<string, any>>(
  items: T[],
  key?: keyof T
): { [key: string]: T[] } =>
  items.reduce((acc: any, item) => {
    // const timestamp = item[key];
    const timestamp = item.content?.date;
    if (timestamp) {
      const date = new Date(timestamp);
      const month = date.toLocaleString("de-DE", {
        month: "long",
        year: "numeric",
      }); // Get month name with year

      if (!acc[month]) {
        acc[month] = [];
      }

      acc[month].push(item);
    }
    return acc;
  }, {});
