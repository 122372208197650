import Link from "next/link";
import Image from "next/image";
import { useState } from "react";
import { storyblokEditable } from "@storyblok/react/rsc";
import { renderRichText } from "@storyblok/react";
import { format, toDate } from "date-fns-tz";
import { de } from "date-fns/locale";

import { H1, H3, RichText } from "@/components/typography";
import { Breadcrumb } from "@/components/layout/Breadcrumb";
import { Button } from "@/components/layout/Button";
import { ModalImage } from "@/components/layout/ModalImage";
import type { EventStoryblok, CampsiteStoryblok } from "@/component-types-sb";

type EventProps = {
  blok: EventStoryblok;
};

// const addWeeksToDate = (date: string, weeksToAdd: number): Date => {
//   const resultDate = new Date(date);
//   const millisecondsInWeek = 7 * 24 * 60 * 60 * 1000;
//   const newTimeValue = resultDate.getTime() + weeksToAdd * millisecondsInWeek;
//   resultDate.setTime(newTimeValue);
//   return resultDate;
// };

const Event = ({ blok }: EventProps) => {
  // const nextSchedules =
  //   blok.repeat === "weekly"
  //     ? [...new Array(4)].map((_, i) => addWeeksToDate(blok.date, i))
  //     : [];

  return (
    <article className="w-full pb-8" {...storyblokEditable(blok)}>
      <section className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
        <div>
          <header>
            <Breadcrumb
              items={[
                { href: "/veranstaltungen", title: "Veranstaltungen" },
                { title: blok.title },
              ]}
            />
            <H1 colorScheme="primary">{blok.title}</H1>
            <H3 as="h2">
              {blok.date &&
                format(
                  toDate(blok.date, { timeZone: "GMT" }),
                  blok.all_day ? "PPPP" : "eeee, PPpp",
                  { locale: de, timeZone: "Europe/Berlin" }
                )}
            </H3>
          </header>

          <RichText html={renderRichText(blok.content)} />

          <div className="flex gap-2">
            {blok.campsite && (
              <Link
                href={[
                  "/",
                  (blok.campsite as unknown as CampsiteStoryblok).full_slug,
                ].join("")}
              >
                <Button colorScheme="primary" className="my-4">
                  {(blok.campsite as unknown as CampsiteStoryblok).name}
                </Button>
              </Link>
            )}

            {blok.link?.cached_url && (
              <Link href={["/", blok.link?.cached_url].join("")}>
                <Button arrow colorScheme="secondary" className="my-4">
                  Mehr Infos
                </Button>
              </Link>
            )}
          </div>

          {/* {nextSchedules.length > 0 && (
            <>
              <div className="relative inline-flex items-center justify-center w-full">
                <hr className="w-64 h-px my-8 bg-gray-200 border-0" />
                <span className="absolute px-3 text-sm font-medium text-gray-500 -translate-x-1/2 bg-white left-1/2">
                  Weitere Termine
                </span>
              </div>

              <ul className="grid grid-cols-2 gap-2">
                {nextSchedules.map((date, index) => (
                  <li key={index}>{formatDate(date)}</li>
                ))}
              </ul>
            </>
          )} */}
        </div>

        <div className="grid grid-flow-col gap-2">
          {blok.images?.map((image) => (
            <ModalImage key={image.id} image={image} />
          ))}
        </div>
      </section>
    </article>
  );
};

export default Event;
