import { storyblokEditable } from "@storyblok/react/rsc";
import { renderRichText } from "@storyblok/react";

import { H4, RichText } from "@/components/typography";
import type { TableStoryblok } from "@/component-types-sb";

type TableProps = {
  blok: TableStoryblok;
};

const Table = ({ blok }: TableProps) => (
  <div className="pb-2" {...storyblokEditable(blok)}>
    <div className="bg-brand-primary100 p-1 mb-4 ">
      <H4
        as="h3"
        colorScheme="white"
        fontWeight="bold"
        fontSize="lg"
        className="uppercase"
      >
        {blok.title}
      </H4>
    </div>

    <div className="relative overflow-x pb-2">
      <table className="w-full text-center text-black">
        <thead className="bg-white uppercase">
          <tr>
            {blok.table?.thead.map((th: any, trIndex: number) => (
              <th scope="col" className="p-3" key={trIndex}>
                {th.value}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {blok.table?.tbody.map((tr: any, trIndex: number) => (
            <tr className="border-b border-gray-50" key={trIndex}>
              {tr.body.map((td: any, tdIndex: number) => (
                <td className="bg-gray-200 px-3 py-2" key={tdIndex}>
                  {td.value}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>

    <RichText html={renderRichText(blok.text)} className="italic" />
  </div>
);

export default Table;
