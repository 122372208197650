import { ReactNode } from "react";
import { clsx } from "clsx";
import type { ColorScheme, FontSize } from "@/lib/types";

type Props = {
  children: ReactNode;
  colorScheme?: ColorScheme;
  fontSize?: FontSize;
  uppercase?: boolean;
};

const H1 = ({
  children,
  colorScheme,
  fontSize = "4xl",
  uppercase = false,
}: Props) => (
  <h1
    className={clsx("font-bold", {
      ["text-brand-primary100"]: colorScheme === "primary",
      ["text-brand-secondary100"]: colorScheme === "secondary",
      ["text-white"]: colorScheme === "white",
      ["md:text-6xl text-5xl"]: fontSize === "6xl",
      ["md:text-5xl text-4xl"]: fontSize === "5xl",
      ["md:text-4xl text-3xl"]: fontSize === "4xl",
      ["md:text-3xl text-2xl"]: fontSize === "3xl",
      ["md:text-2xl text-1xl"]: fontSize === "2xl",
      ["uppercase"]: uppercase,
    })}
  >
    {children}
  </h1>
);

export { H1 };
