import { storyblokEditable } from "@storyblok/react/rsc";
import { BodyText as BodyTextComponent } from "@/components/typography";
import type { BodyTextStoryblok } from "@/component-types-sb";

type BodyTextProps = {
  blok: BodyTextStoryblok;
};

const BodyText = ({ blok }: BodyTextProps) => (
  <BodyTextComponent {...storyblokEditable(blok)}>
    {blok.content}
  </BodyTextComponent>
);

export default BodyText;
