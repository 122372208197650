"use client";

import Link from "next/link";
import Image from "next/image";
import { storyblokEditable } from "@storyblok/react/rsc";
import { track } from "@vercel/analytics";

import type { OfferTeaserStoryblok } from "@/component-types-sb";
import imageLoader from "@/lib/utils/imageLoader";

type OfferTeaserProps = {
  blok: OfferTeaserStoryblok;
};

const OfferTeaser = ({ blok }: OfferTeaserProps) => (
  <Link href={blok.link?.cached_url || "#"} {...storyblokEditable(blok)}>
    <Image
      className="object-contain w-full h-auto"
      loader={imageLoader}
      src={blok.image?.filename || ""}
      width={1628}
      height={200}
      alt={blok.image?.alt || ""}
      onClick={() => {
        track("Offer click", { offer: blok.link.story.name });
      }}
    />
    <p className="py-4 text-xs text-gray-500 text-center">{blok.text}</p>
  </Link>
);

export default OfferTeaser;
