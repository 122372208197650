import { storyblokEditable } from "@storyblok/react/rsc";
import { GoogleMapsEmbed } from "@next/third-parties/google";
import type { MapStoryblok } from "@/component-types-sb";

type MapProps = {
  blok: MapStoryblok;
};

const Map = ({ blok }: MapProps) => (
  <div {...storyblokEditable(blok)} key={blok._uid} className="w-full h-auto">
    <GoogleMapsEmbed
      apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY as string}
      height={400}
      mode="place"
      style="width:100%; height:100%; aspect-ratio:16/12;"
      allowfullscreen={true}
      loading="lazy"
      q={blok.location}
    />
  </div>
);

export default Map;
