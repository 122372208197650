import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";
import { H2, Divider, BodyText } from "@/components/typography";
import type { AwardsStoryblok } from "@/component-types-sb";

type AwardsPops = {
  blok: AwardsStoryblok;
};

const Awards = ({ blok }: AwardsPops) => (
  <section className="py-8 text-center" {...storyblokEditable(blok)}>
    <H2 colorScheme="primary">{blok.headline}</H2>
    <BodyText className="max-w-md mx-auto mt-4 text-center">
      {blok.text}
    </BodyText>

    <Divider />

    <div className="flex gap-12 justify-center py-8 bg-gray-200">
      {blok.body?.map((nestedBlok) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
    </div>
  </section>
);

export default Awards;
