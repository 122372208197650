import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";
import { clsx } from "clsx";

import { H2, Divider, BodyText } from "@/components/typography";
import type { ContainerStoryblok } from "@/component-types-sb";

type ContainerPops = {
  blok: ContainerStoryblok;
};

const Container = ({ blok }: ContainerPops) => (
  <section
    className={clsx("py-8 px-4 lg:px-0 text-center", {
      ["container mx-auto"]: blok.layout === "constrained",
      ["full-width"]: blok.layout === "full-width",
    })}
    {...storyblokEditable(blok)}
  >
    <H2 colorScheme="primary">{blok.headline}</H2>
    <BodyText className="max-w-2xl mx-auto mt-4 text-center">
      {blok.text}
    </BodyText>

    <Divider />

    <div className="py-8 text-left">
      {blok.body?.map((nestedBlok) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
    </div>
  </section>
);

export default Container;
