import {
  storyblokEditable,
  SbBlokData,
  ISbRichtext,
} from "@storyblok/react/rsc";
import { renderRichText } from "@storyblok/react";
import { RichText as RichTextComponent } from "@/components/typography";

type RichTextProps = {
  blok: SbBlokData;
  className?: string;
};

const RichText = ({ blok, className }: RichTextProps) => (
  <RichTextComponent
    html={renderRichText(blok.content as ISbRichtext)}
    className={className}
    {...storyblokEditable(blok)}
  />
);
export default RichText;
