import { storyblokEditable } from "@storyblok/react/rsc";
import { renderRichText } from "@storyblok/react";

import { H2, H3, RichText, Divider } from "@/components/typography";
import { Card } from "@/components/layout/Card";
import type { CycleRouteStoryblok } from "@/component-types-sb";

type CycleRouteProps = {
  blok: CycleRouteStoryblok;
};

const CycleRoute = ({ blok }: CycleRouteProps) => {
  return (
    <article className="w-full" {...storyblokEditable(blok)}>
      <section className="py-8 text-center">
        <header>
          <H2 colorScheme="primary">{blok.name}</H2>
        </header>
        <div className="p-8 bg-gray-200 text-left">
          <RichText html={renderRichText(blok.content)} />
        </div>

        <Divider />

        <H3>{blok.subline}</H3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
          {blok.campsites?.map((nestedBlok: any) => (
            <Card
              key={nestedBlok.id}
              image={nestedBlok.content?.image}
              href={nestedBlok.full_slug}
              name={nestedBlok.name}
              text={nestedBlok.content?.lead}
              buttonLabel="Entdecken"
            />
          ))}
        </div>
      </section>
    </article>
  );
};

export default CycleRoute;
