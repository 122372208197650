import { StoryblokComponent } from "@storyblok/react/rsc";
import type { BannerStoryblok } from "@/component-types-sb";

type BannerPops = {
  blok: BannerStoryblok;
};

const Banner = ({ blok }: BannerPops) =>
  blok.content?.map((nestedBlok) => (
    <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
  ));

export default Banner;
