import type { ComponentPropsWithoutRef, ElementType } from "react";
import { clsx } from "clsx";
import type { ColorScheme, FontSize, FontWeight } from "@/lib/types";

type Props<TElementType extends ElementType> =
  ComponentPropsWithoutRef<TElementType> & {
    colorScheme?: ColorScheme;
    fontSize?: FontSize;
    fontWeight?: FontWeight;
    as?: Extract<TElementType, "h1" | "h2" | "h3" | "h4">;
  };

const H4 = <TElementType extends ElementType>({
  children,
  colorScheme = "secondary",
  fontSize = "3xl",
  fontWeight = "semibold",
  as,
  ...rest
}: Props<TElementType>) => {
  const Headline = as ?? "h4";

  return (
    <Headline
      className={clsx("tracking-tight", rest.className, {
        ["text-brand-primary100"]: colorScheme === "primary",
        ["text-brand-secondary100"]: colorScheme === "secondary",
        ["text-white"]: colorScheme === "white",
        ["text-gray-900"]: colorScheme === "black",
        ["text-4xl"]: fontSize === "4xl",
        ["text-3xl"]: fontSize === "3xl",
        ["text-2xl"]: fontSize === "2xl",
        ["text-lg"]: fontSize === "lg",
        ["text-base"]: fontSize === "base",
        ["font-extrabold"]: fontWeight === "extrabold",
        ["font-semibold"]: fontWeight === "semibold",
        ["font-bold"]: fontWeight === "bold",
      })}
    >
      {children}
    </Headline>
  );
};

export { H4 };
