"use client";

import Link from "next/link";
import { useEffect, useState } from "react";
import { track } from "@vercel/analytics";

import { getOffers } from "@/lib/storyblok/getOffers";
import type { OfferStoryblok } from "@/component-types-sb";

type OfferTeaserProps = {
  campsiteId?: string;
  count?: number;
};

const OfferTeaser = ({ campsiteId, count = 3 }: OfferTeaserProps) => {
  const [offers, setOffers] = useState<OfferStoryblok[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getOffers({
        filter_query: {
          campsites: { in_array: campsiteId },
        },
        per_page: count,
      });
      setOffers(data);
      setIsLoading(false);
    };
    fetchData();
  }, [campsiteId, count]);

  return (
    <div className="flex flex-col gap-2 mt-4">
      {isLoading && <Skeleton number={count} />}

      {!isLoading && offers.length > 0 && (
        <>
          <Link href="/veranstaltungen">
            <span className="font-semibold text-brand-primary100">
              Angebote
            </span>
          </Link>
          <ul className="w-full text-sm">
            {offers?.map((blok) => (
              <li key={blok.id} className="px-2 py-2 border-b border-gray-200">
                <Link
                  href={blok.full_slug}
                  onClick={() => track("Event click")}
                  className="flex gap-2"
                >
                  {blok.name}
                </Link>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

const Skeleton = ({ number = 1 }) => (
  <>
    <div
      role="status"
      className="w-8/12 mb-1 h-6 bg-gray-200 animate-pulse"
    ></div>
    {Array(number)
      .fill(0)
      .map((_, index) => (
        <div
          key={index}
          className="w-12/12 my-1 h-4 bg-gray-200 animate-pulse"
        ></div>
      ))}
  </>
);

export { OfferTeaser };
