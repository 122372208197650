"use client";

import { useEffect, useState } from "react";
import { storyblokEditable } from "@storyblok/react/rsc";

import { Card } from "@/components/layout/Card";
import { H2, BodyText, Divider } from "@/components/typography";
import type {
  CategoryStoryblok,
  CampsiteStoryblok,
} from "@/component-types-sb";
import { getCampsites } from "@/lib/storyblok/getCampsites";

type CategoryProps = {
  blok: CategoryStoryblok;
  uuid: string;
};

const Category = ({ blok, uuid }: CategoryProps) => {
  const [campsites, setCampsites] = useState<CampsiteStoryblok[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getCampsites({
        filter_query: {
          categories: {
            in_array: uuid,
          },
        },
      });
      setCampsites(data);
      setIsLoading(false);
    };
    fetchData();
  }, [uuid]);

  return (
    <article className="w-full" {...storyblokEditable(blok)}>
      <section className="py-8 mx-2 lg:mx-4 text-center">
        <header>
          <H2 as="h1" colorScheme="primary">
            {blok.name}
          </H2>
          <BodyText className="max-w-md mx-auto mt-2 text-center">
            {blok.subline}
          </BodyText>
        </header>

        <Divider />

        <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
          {isLoading && <Skeleton number={3} />}

          {campsites.map((nestedBlok) => (
            <Card
              image={nestedBlok.content?.image}
              href={nestedBlok.full_slug}
              name={nestedBlok.name}
              text={nestedBlok.content?.lead}
              key={nestedBlok.id}
              buttonLabel="Entdecken"
            />
          ))}
        </div>
      </section>
    </article>
  );
};

const Skeleton = ({ number = 1 }) =>
  Array(number)
    .fill(0)
    .map((_, index) => (
      <div key={index} className="w-full h-96 bg-gray-200 animate-pulse"></div>
    ));

export default Category;
