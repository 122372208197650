import type { ComponentPropsWithoutRef } from "react";
import { clsx } from "clsx";

type Props = ComponentPropsWithoutRef<"div"> & {
  html: string;
};

const RichText = ({ html, ...rest }: Props) => (
  <div
    className={clsx(
      "break-words prose max-w-none prose-li:list-image-checkmark [&>ul>li>p]:my-2 prose-a:underline prose-a:decoration-2 prose-a:decoration-brand-primary100 prose-h4:text-brand-primary100 prose-h4:font-bold",
      rest.className
    )}
    dangerouslySetInnerHTML={{
      __html: html,
    }}
  />
);

export { RichText };
