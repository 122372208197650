import { useState, ReactNode } from "react";
import { CampsiteContext } from "./CampsiteContext";

const CampsiteProvider = ({ children }: { children: ReactNode }) => {
  const [code, setCode] = useState("");
  const [id, setId] = useState("");

  return (
    <CampsiteContext.Provider value={{ code, setCode, id, setId }}>
      {children}
    </CampsiteContext.Provider>
  );
};

export { CampsiteProvider };
