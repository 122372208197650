import Link from "next/link";
import { useState, useEffect, useContext, useRef, useCallback } from "react";
import type { MouseEvent } from "react";
import { storyblokEditable } from "@storyblok/react/rsc";
import { Transition } from "@headlessui/react";
import { BellAlertIcon, XMarkIcon } from "@heroicons/react/24/solid";

import { CampsiteContext } from "@/lib/context/CampsiteContext";
import { useCookie } from "@/lib/hooks/useCookie";
import { setProperty } from "@/lib/utils/styles";
import type { BannerItemStoryblok } from "@/component-types-sb";

type BannerItemPops = {
  blok: BannerItemStoryblok;
};

const BannerItem = ({ blok }: BannerItemPops) => {
  const [isHidden, setIsHidden] = useState(true);
  const [cookie, setCookie] = useCookie(blok._uid, "");
  const { id } = useContext(CampsiteContext);
  const bannerRef = useRef<HTMLDivElement>(null);

  const hide = useCallback(() => {
    setProperty("--header-top", "0px");
    setIsHidden(true);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      blok.mode === "auto" && hide();
    }, 10000);

    return () => clearTimeout(timer);
  }, [blok.mode, hide]);

  useEffect(() => {
    /**
     * Don't show banner if
     * - closed before
     * - does not match current campsite
     */
    if (
      cookie === "true" ||
      (blok.campsites?.length && !blok.campsites.includes(id))
    ) {
      hide();
    } else {
      const height = bannerRef.current?.offsetHeight || 64;
      setProperty("--header-top", `${height}px`);
      setIsHidden(false);
    }
    return () => hide();
  }, [blok.campsites, id, cookie, setIsHidden, hide]);

  const handleButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setCookie("true");
    hide();
  };

  return (
    <Transition
      appear={true}
      show={!isHidden}
      className="absolute w-full"
      enter="transition ease-in duration-300 transform-all"
      enterFrom="opacity-0 -translate-y-full"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-out duration-300 transform-all"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 -translate-y-full"
    >
      <div
        ref={bannerRef}
        className="relative z-10 bg-brand-primary100"
        {...storyblokEditable(blok)}
      >
        <div className="max-w-8xl mx-auto px-4 py-3 flex items-start justify-between text-white md:px-8">
          <Link href={blok.link?.cached_url || "#"}>
            <div className="flex gap-x-4">
              <div className="w-10 h-10 flex-none rounded-lg bg-brand-primary200 flex items-center justify-center">
                <BellAlertIcon className="w-6 h-6" aria-hidden="true" />
              </div>
              <p className="py-2 font-medium duration-150 hover:text-indigo-100">
                {blok?.title}
              </p>
            </div>
          </Link>
          {blok?.mode === "confirm" && (
            <button
              className="p-2 rounded-lg duration-150 hover:bg-brand-primary50 ring-offset-2 focus:ring"
              onClick={handleButtonClick}
              aria-label="Schliessen"
            >
              <XMarkIcon className="w-6 h-6" />
            </button>
          )}
        </div>
      </div>
    </Transition>
  );
};

export default BannerItem;
