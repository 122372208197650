import Image from "next/image";
import Link from "next/link";
import { storyblokEditable } from "@storyblok/react/rsc";
import { renderRichText } from "@storyblok/react";

import { H3, RichText } from "@/components/typography";
import type { AccommodationStoryblok } from "@/component-types-sb";
import imageLoader from "@/lib/utils/imageLoader";

type AccommodationProps = {
  blok: AccommodationStoryblok;
};

const Accommodation = ({ blok }: AccommodationProps) => {
  return (
    <article className="mx-2 lg:mx-0" {...storyblokEditable(blok)}>
      <section className="grid grid-cols-3 gap-8">
        <div className="col-span-3 md:col-span-2 md:p-8">
          <header>
            <H3 as="h2">{blok.name}</H3>
          </header>

          <RichText html={renderRichText(blok.content)} />

          <div className="grid grid-cols-1 gap-2 lg:grid-cols-4 mt-8">
            {blok.images?.map((image) => (
              <figure key={image.id} className="relative block group">
                <Image
                  className="object-cover w-full transition duration-500 aspect-square group-hover:opacity-90"
                  loader={imageLoader}
                  src={image.filename}
                  width={300}
                  height={200}
                  sizes="20vw"
                  alt={image.alt || ""}
                />

                <figcaption className="absolute inset-0 flex flex-col items-start justify-end p-6">
                  <h3 className="text-lg font-medium text-white">
                    {image.title}
                  </h3>
                </figcaption>
              </figure>
            ))}
          </div>
        </div>
        <aside className="col-span-3 md:col-span-1 md:p-8">
          <H3 colorScheme="primary">Campingparks</H3>
          <ul className="divide-y divide-gray-200">
            {blok.campsites?.map((nestedBlok: any) => (
              <li key={nestedBlok.id} className="py-2">
                <Link href={["/", nestedBlok.full_slug].join("")}>
                  {nestedBlok.name}
                </Link>
              </li>
            ))}
          </ul>
        </aside>
      </section>
    </article>
  );
};

export default Accommodation;
