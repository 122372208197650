import { SbBlokData } from "@storyblok/react/rsc";

type CustomFallbackComponentPops = {
  blok: SbBlokData;
};

const CustomFallbackComponent = ({ blok }: CustomFallbackComponentPops) => {
  return (
    <div className="bg-red-50 border rounded border-red-600 px-8 py-4">
      <p className="text-center text-red-600">
        No component defined for <strong>{blok.component}</strong>
      </p>
    </div>
  );
};

export default CustomFallbackComponent;
