import { StarIcon } from "@heroicons/react/24/solid";

type StarsProps = {
  count?: number | string;
};

const Stars = ({ count = 1 }: StarsProps) => {
  count = typeof count === "string" ? Number(count) : count;

  return (
    <>
      {[...Array(count)].map((_number, index) => (
        <StarIcon key={index} className="w-4 h-4 fill-brand-primary100" />
      ))}
    </>
  );
};

export { Stars };
