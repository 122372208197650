import { storyblokEditable } from "@storyblok/react/rsc";
import type { TravelStoryblok } from "@/component-types-sb";

type TravelProps = {
  blok: TravelStoryblok;
};

const Travel = ({ blok }: TravelProps) => (
  <div {...storyblokEditable(blok)} key={blok._uid} className="p-3">
    <span className="text-brand-primary100 text-lg font-semibold">
      {blok.type}
    </span>

    <p className="text-left font-medium leading-5 text-gray-500">{blok.text}</p>
  </div>
);

export default Travel;
