import { ReactNode } from "react";
// import Link from "next/link";
import { storyblokEditable } from "@storyblok/react/rsc";
import { Button as ButtonComponent } from "@/components/layout/Button";
import type { ColorScheme } from "@/components/layout/Button";
import type { ButtonStoryblok } from "@/component-types-sb";

type ButtonProps = {
  blok: ButtonStoryblok;
};

type LinkWrapperProps = {
  href?: string;
  children: ReactNode;
};

// const LinkWrapper = ({ href, children }: LinkWrapperProps) => {
//   return href ? <Link href={href}>{children}</Link> : children;
// };

const Button = ({ blok }: ButtonProps) => (
  <ButtonComponent
    colorScheme={blok?.button_color as ColorScheme}
    {...storyblokEditable(blok)}
  >
    {blok.label}
  </ButtonComponent>
);

export default Button;
