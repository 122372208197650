import { ReactNode } from "react";
import { clsx } from "clsx";
import type { ColorScheme } from "@/lib/types";

type TagProps = {
  children: ReactNode;
  colorScheme?: ColorScheme;
};

const Tag = ({ children, colorScheme = "primary" }: TagProps) => (
  <span
    className={clsx(
      "leading-sm inline-flex items-center border-2 px-2 py-0.5 text-xs font-semibold uppercase shadow-sm",
      {
        ["border-brand-primary100 bg-brand-primary100 text-white"]:
          colorScheme === "primary",
        ["border-brand-secondary100 bg-brand-secondary100 text-white"]:
          colorScheme === "secondary",
        ["border-brand-primary100 bg-white text-brand-primary100"]:
          colorScheme === "outline",
        ["border-brand-teal bg-brand-teal text-white"]: colorScheme === "teal",
        ["border-brand-beige bg-brand-beige text-white"]:
          colorScheme === "beige",
        ["border-brand-brown bg-brand-brown text-white"]:
          colorScheme === "brown",
      }
    )}
  >
    {children}
  </span>
);

export { Tag };
