"use client";

import { Children, useState, useEffect, useCallback } from "react";
import type { PropsWithChildren, KeyboardEvent } from "react";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/20/solid";
import { clsx } from "clsx";
import useEmblaCarousel, {
  type EmblaCarouselType,
  type EmblaOptionsType,
} from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";

type CarouselProps = PropsWithChildren & {
  className?: string;
  id?: string;
  autoplay?: boolean;
  indicator?: boolean;
  options?: EmblaOptionsType;
};

const Carousel = ({
  children,
  className,
  id = "carousel",
  autoplay = false,
  indicator = false,
  options = {},
}: CarouselProps) => {
  const childrenArray = Children.toArray(children);
  const [emblaRef, emblaApi] = useEmblaCarousel(options, [
    Autoplay({ delay: 8000, playOnInit: autoplay }),
  ]);

  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  const scrollPrev = useCallback(
    () => emblaApi && emblaApi.scrollPrev(),
    [emblaApi]
  );
  const scrollNext = useCallback(
    () => emblaApi && emblaApi.scrollNext(),
    [emblaApi]
  );

  const scrollTo = useCallback(
    (index: number) => emblaApi && emblaApi.scrollTo(index),
    [emblaApi]
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (event.key === "ArrowLeft") {
        scrollPrev();
      } else if (event.key === "ArrowRight") {
        scrollNext();
      }
    },
    [scrollNext, scrollPrev]
  );

  const onInit = useCallback((emblaApi: EmblaCarouselType) => {
    setScrollSnaps(emblaApi.scrollSnapList());
  }, []);

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onInit(emblaApi);
    onSelect(emblaApi);
    emblaApi.on("reInit", onInit);
    emblaApi.on("reInit", onSelect);
    emblaApi.on("select", onSelect);
  }, [emblaApi, onSelect, onInit]);

  return (
    <>
      <div
        className="flex relative items-center overflow-hidden"
        ref={emblaRef}
        tabIndex={0}
        onKeyDown={handleKeyDown}
      >
        <div className="flex w-full" id={id}>
          {childrenArray.map((item, index) => (
            <div
              key={index}
              className={clsx("min-w-0 flex-grow-0 flex-shrink-0", className)}
            >
              {item}
            </div>
          ))}
        </div>

        {/* Infos */}
        <div
          className={clsx(
            "z-10 md:p-2 text-white text-[10px] md:text-xs bg-black/30 rounded-lg backdrop-blur-lg absolute top-2 right-3 p-1",
            {
              ["hidden"]: childrenArray.length <= 1,
            }
          )}
        >
          <span>
            {selectedIndex + 1}/{emblaApi?.slideNodes().length}
          </span>
        </div>

        {/* Controls */}
        <button
          aria-label="Zurück"
          onClick={scrollPrev}
          className={clsx(
            "hidden md:flex absolute left-0 z-1 items-center justify-center p-2 cursor-pointer group focus:outline-none pointer-events-auto transition-opacity ease-out duration-400",
            {
              ["hidden"]: prevBtnDisabled,
            }
          )}
        >
          <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-black/30 group-hover:bg-white/50 ring-2 ring-white group-focus:outline-none">
            <ChevronLeftIcon
              className="w-full h-full text-white group-hover:text-brand-primary50"
              aria-hidden="true"
            />
          </span>
        </button>

        <button
          aria-label="Weiter"
          onClick={scrollNext}
          className={clsx(
            "hidden md:flex absolute right-0 z-1 items-center justify-center p-2 cursor-pointer group focus:outline-none pointer-events-auto transition-opacity ease-out duration-400",
            {
              ["hidden"]: nextBtnDisabled,
            }
          )}
        >
          <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-black/30 group-hover:bg-white/50 ring-2 ring-white group-focus:outline-none">
            <ChevronRightIcon
              className="w-full h-full text-white group-hover:text-brand-primary50"
              aria-hidden="true"
            />
          </span>
        </button>
      </div>

      {/* Indicator */}
      {indicator && (
        <div
          className="hidden md:flex w-full relative justify-center gap-3 my-8 overflow-x-auto"
          role="progressbar"
          aria-labelledby={id}
          aria-valuenow={selectedIndex}
          aria-valuemin={0}
          aria-valuemax={scrollSnaps.length}
        >
          {scrollSnaps.map((_, index) => (
            <button
              key={index}
              aria-label={`Slide ${index}`}
              onClick={() => scrollTo(index)}
            >
              <div
                className={clsx(
                  "h-2 w-2 rounded-full transition-all duration-300 bg-brand-primary100",
                  {
                    ["bg-opacity-30"]: index !== selectedIndex,
                    ["bg-opacity-100"]: index === selectedIndex,
                  }
                )}
              />
            </button>
          ))}
        </div>
      )}
    </>
  );
};

export { Carousel };
