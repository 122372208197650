"use client";

import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";
import { ArrowDownIcon } from "@heroicons/react/20/solid";
import { renderRichText } from "@storyblok/react";
import { getStoryblokApi, ISbStoriesParams } from "@storyblok/react/rsc";
import { startOfMonth, endOfMonth, subSeconds } from "date-fns";
import { format, toDate } from "date-fns-tz";
import { de } from "date-fns/locale";
import { sortBy } from "lodash";

import { Skeleton } from "@/components/layout/Skeleton";
import { H3, RichText } from "@/components/typography";
// import { getEvents } from "@/lib/storyblok/getEvents";
import type { EventStoryblok } from "@/component-types-sb";

type EventListProps = {
  date: Date;
  campsiteId?: string;
  maxEvents?: number;
};

const getEvents = async ({
  ...rest
}: ISbStoriesParams): Promise<EventStoryblok[]> => {
  const preview = process.env.NEXT_PUBLIC_VERCEL_ENV === "preview";

  let sbParams: ISbStoriesParams = {
    version: preview ? "draft" : "published",
    cv: preview ? Date.now() : undefined,
    content_type: "event",
    resolve_relations: "event.campsite",
    sort_by: "position:asc",
    is_startpage: false,
    ...rest,
  };
  const storyblokApi = getStoryblokApi();
  return await storyblokApi.getAll("cdn/stories", sbParams);
};

const EventList = ({
  date = new Date(),
  campsiteId,
  maxEvents = 4,
}: EventListProps) => {
  const [events, setEvents] = useState<EventStoryblok[]>([]);
  const [visibleEventsCount, setVisibleEventsCount] = useState(maxEvents);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getEvents({
        filter_query: {
          date: {
            gt_date: format(
              subSeconds(startOfMonth(date), 1),
              "yyyy-MM-dd HH:mm",
              { timeZone: "Europe/Berlin" }
            ),
            lt_date: format(endOfMonth(date), "yyyy-MM-dd HH:mm"),
          },
          campsite: { in: campsiteId || null },
        },
      });
      const sortedByDate = sortBy(data, ["content.date"]);
      setEvents(sortedByDate);
      setIsLoading(false);
    };
    fetchData();
  }, [date, campsiteId]);

  if (!isLoading && events.length === 0) {
    return;
  }

  return (
    <section className="relative pl-8 sm:pl-32 py-6 group">
      <H3 as="h2" colorScheme="secondary" className="mb-8">
        {format(date, "LLLL yyyy", { locale: de, timeZone: "Europe/Berlin" })}
      </H3>

      {isLoading && <Skeleton number={maxEvents} />}

      {events.slice(0, visibleEventsCount).map((blok) => (
        <div
          key={blok.id}
          className="flex flex-col sm:flex-row items-start mb-4 group-last:before:hidden before:absolute before:left-2 sm:before:left-0 before:h-full before:px-px before:bg-gray-200 sm:before:ml-[6.5rem] before:self-start before:-translate-x-1/2 before:translate-y-3 after:absolute after:left-2 sm:after:left-0 after:w-2 after:h-2 after:bg-brand-primary100 after:border-4 after:box-content after:border-gray-50 after:rounded-full sm:after:ml-[6.5rem] after:-translate-x-1/2 after:translate-y-1.5"
        >
          <time className="sm:absolute left-0 translate-y-0.5 inline-flex items-center justify-center text-xs font-semibold uppercase w-20 h-6 mb-3 sm:mb-0 text-white bg-brand-secondary100 rounded-full">
            {format(toDate(blok.content?.date, { timeZone: "GMT" }), "d. LLL", {
              locale: de,
              timeZone: "Europe/Berlin",
            })}
          </time>

          <Link href={blok.full_slug} className="flex gap-8 mb-4">
            <Image
              className="object-cover w-24 h-24 aspect-square"
              src={
                blok.content?.images?.[0]?.filename ||
                blok.content?.campsite?.content?.image?.filename
              }
              width={96}
              height={96}
              sizes="10vw"
              alt={blok.content?.campsite?.content?.image.alt || ""}
            />
            <div>
              <span className="font-light">{blok.content?.campsite?.name}</span>
              <h3 className="text-lg font-semibold text-gray-900">
                {blok.name}
              </h3>
              <RichText
                className="font-normal line-clamp-2 text-gray-500 leading-5"
                html={renderRichText(blok.content?.content as any)}
              />
            </div>
          </Link>
        </div>
      ))}

      {!isLoading && events.length == 0 && (
        <span className="opacity-50 text-center">Keine Veranstaltungen</span>
      )}

      {visibleEventsCount < events.length && (
        <button
          className="opacity-50 hover:opacity-100 flex flex-col items-center ml-32"
          onClick={() => {
            setVisibleEventsCount(
              Math.min(visibleEventsCount + maxEvents, events.length)
            );
          }}
        >
          {events.length - visibleEventsCount} weitere Veranstaltungen
          anzeigen...
          <ArrowDownIcon className="w-4 h-4" />
        </button>
      )}
    </section>
  );
};

export { EventList };
