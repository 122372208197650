"use client";

import Link from "next/link";
import Image from "next/image";
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";
import { storyblokEditable } from "@storyblok/react/rsc";
import { renderRichText } from "@storyblok/react";
import { YouTubeEmbed } from "@next/third-parties/google";
import { format, parse } from "date-fns";
import { de } from "date-fns/locale";
import { sortBy } from "lodash";
import { siteUrl } from "@/config/site";

import { Breadcrumb } from "@/components/layout/Breadcrumb";
import { Button } from "@/components/layout/Button";
import { H1, H3, RichText } from "@/components/typography";
import { getNews } from "@/lib/storyblok/getNews";
import type { NewsStoryblok, CampsiteStoryblok } from "@/component-types-sb";

type NewsProps = {
  blok: NewsStoryblok & {
    campsite: CampsiteStoryblok;
  };
};

const News = ({ blok }: NewsProps) => {
  const [news, setNews] = useState<NewsStoryblok[]>([]);

  const pathname = usePathname();

  useEffect(() => {
    const fetchData = async () => {
      const data = await getNews({
        filter_query: {
          campsite: {
            is: blok.campsite.uuid,
          },
          _uid: { not_in: blok._uid },
        },
      });
      const sortedByDate = sortBy(data, ["content.date"]);
      setNews(sortedByDate);
    };
    fetchData();
  }, [blok]);

  return (
    <article className="w-full" {...storyblokEditable(blok)}>
      <section className="relative max-w-6xl mx-auto grid grid-cols-3 gap-8 p-4">
        <div className="col-span-3 md:col-span-2 md:p-8">
          <header className="col-span-12">
            <Breadcrumb
              items={[
                { href: "/news", title: "Nachrichten" },
                { title: blok.title },
              ]}
            />
            <H1 colorScheme="primary">{blok.title}</H1>
            {blok.date && (
              <H3 as="h2">
                {format(
                  parse(blok.date, "yyyy-MM-dd HH:mm", new Date()),
                  "PPPP",
                  { locale: de }
                )}
              </H3>
            )}
          </header>

          <p className="text-xl col-span-12 lg:col-span-7">{blok.abstract}</p>

          <div className="my-8">
            {blok.videoid ? (
              <div className="w-full h-auto aspect-video">
                <YouTubeEmbed
                  videoid={blok.videoid}
                  params={`controls=2&modestbranding=1&${blok.videoparams}`}
                  width={720}
                  height={405}
                />
              </div>
            ) : (
              <div>
                {blok.images?.[0] && (
                  <Image
                    key={blok.images[0].id}
                    className={"w-full object-cover aspect-video"}
                    src={blok.images[0]?.filename}
                    width={300}
                    height={300}
                    sizes="50vw"
                    alt={blok.images[0].alt || ""}
                  />
                )}

                <div className="grid grid-cols-3 gap-2 mt-2">
                  {blok.images?.slice(1, blok.images?.length).map((image) => (
                    <Image
                      key={image.id}
                      className={"w-full object-cover aspect-square"}
                      src={image?.filename}
                      width={300}
                      height={300}
                      sizes="20vw"
                      alt={image.alt || ""}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>

          <RichText html={renderRichText(blok.content)} />

          {blok.campsite && (
            <Link href={`/${blok.campsite.full_slug}`}>
              <Button colorScheme="primary" className="my-4">
                {blok.campsite.name}
              </Button>
            </Link>
          )}
        </div>

        <aside className="col-span-3 md:col-span-1 md:p-8">
          {news?.length > 0 && (
            <div>
              <Link href="/veranstaltungen">
                <span className="font-semibold text-brand-primary100">
                  Weitere Nachrichten
                </span>
              </Link>
              <ul className="w-full text-sm">
                {news.map((blok) => (
                  <li
                    key={blok.id}
                    className="px-2 py-2 border-b border-gray-200"
                  >
                    <Link href={`/${blok.full_slug}`} className="flex gap-2">
                      {/* {blok.content?.images?.[0] && (
                        <Image
                          className="w-12 h-12 object-cover aspect-square"
                          src={blok.content?.images[0].filename || ""}
                          width={64}
                          height={64}
                          sizes="(max-width: 1024px) 100vw, 25vw"
                          priority={false}
                          alt={blok.content?.images[0].alt || ""}
                        />
                      )} */}
                      <time dateTime={blok.content?.date}>
                        {format(
                          parse(
                            blok.content?.date,
                            "yyyy-MM-dd HH:mm",
                            new Date()
                          ),
                          "dd.MM."
                        )}
                      </time>
                      {blok.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          )}

          <div className="mt-8">
            <span className="font-semibold text-brand-primary100">
              Empfehlen
            </span>

            <ul className="flex gap-4 text-slate-400 my-2">
              <li>
                <a
                  href={`https://www.facebook.com/sharer/sharer.php?u=${siteUrl}${pathname}`}
                  rel="noopener"
                  target="_blank"
                >
                  <span className="sr-only">Facebook</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
                  </svg>
                </a>
              </li>
              {/* <li>
                <a href="#" rel="noopener" target="_blank">
                  <span className="sr-only">Instagram</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                  </svg>
                </a>
              </li> */}
              <li>
                <a
                  href={`whatsapp://send?text=${encodeURIComponent(
                    siteUrl + pathname
                  )}`}
                  rel="noopener"
                  target="_blank"
                >
                  <span className="sr-only">Whatsapp</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z" />
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href={`https://twitter.com/intent/tweet?url=${siteUrl}${pathname}`}
                  rel="noopener"
                  target="_blank"
                >
                  <span className="sr-only">Twitter</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
                  </svg>
                </a>
              </li>
            </ul>
          </div>

          <div className="mt-8">
            <span className="font-semibold text-brand-primary100">
              Pressekontakt
            </span>
            <address className="not-italic text-sm leading-relaxed py-2">
              Helmut KNAUS KG
              <br />
              Markbreiter Str. 11
              <br />
              D-97199 Ochsenfurt
              <br />
              Tel.: +49 9331 98 318-0
              <br />
              E-Mail:
              <a href="mailto:presse@knauscamp.de">presse@knauscamp.de</a>
            </address>
          </div>
        </aside>
      </section>
    </article>
  );
};

export default News;
