import Image from "next/image";
import { useState } from "react";

import { Modal } from "@/components/layout/Modal";
import type { AssetStoryblok } from "@/component-types-sb";
import imageLoader from "@/lib/utils/imageLoader";
import { useMediaQuery } from "@/lib/hooks/useMediaQuery";

type ModalImageProps = {
  image: AssetStoryblok;
};

const ModalImage = ({ image }: ModalImageProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const lg = useMediaQuery("(min-width: 1024px)");

  return (
    <>
      <Image
        key={image.id}
        className="object-cover w-full transition duration-500 aspect-square"
        loader={imageLoader}
        src={image.filename}
        width={300}
        height={300}
        sizes="20vw"
        alt={image.alt || ""}
        onClick={(e) => {
          e.preventDefault();
          if (lg) {
            setModalOpen(true);
          }
        }}
      />

      <Modal
        className="max-w-[1300px]"
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <Image
          className="object-cover w-full"
          loader={imageLoader}
          src={image.filename || ""}
          width={300}
          height={300}
          alt={""}
        />
      </Modal>
    </>
  );
};

export { ModalImage };
