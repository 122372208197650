import { storyblokEditable } from "@storyblok/react/rsc";
import { Card } from "@/components/layout/Card";
import { Carousel as CarouselComponent } from "@/components/layout/Carousel";

import { H2, Divider, BodyText } from "@/components/typography";
import type { CarouselStoryblok } from "@/component-types-sb";
import { useMediaQuery } from "@/lib/hooks/useMediaQuery";

type CarouselPops = {
  blok: CarouselStoryblok;
};

const Carousel = ({ blok }: CarouselPops) => {
  const lg = useMediaQuery("(min-width: 1024px)");
  const md = useMediaQuery("(min-width: 768px)");

  return (
    <section className="py-8 text-center" {...storyblokEditable(blok)}>
      <H2 colorScheme="primary">{blok.headline}</H2>
      <BodyText className="max-w-md mx-auto mt-4 text-center">
        {blok.text}
      </BodyText>

      <Divider />
      <div className="mb-10 md:mx-24 text-left">
        <CarouselComponent
          className="basis-full md:basis-1/2 lg:basis-1/3 lg mr-3"
          indicator
          options={{ slidesToScroll: lg ? 3 : md ? 2 : 1, loop: true }}
        >
          {blok.items?.map((nestedBlok: any) => (
            <Card
              key={nestedBlok.id}
              image={nestedBlok.content?.image}
              href={nestedBlok.full_slug}
              name={nestedBlok.name}
              text={nestedBlok.content?.lead}
              isNew={nestedBlok.content.is_new}
              size="tall"
            />
          ))}
        </CarouselComponent>
      </div>
    </section>
  );
};

Carousel.blockName = "carousel";

export default Carousel;
