import { createContext, Dispatch, SetStateAction } from "react";

type ContextType = {
  code: string;
  setCode: Dispatch<SetStateAction<string>>;
  id: string;
  setId: Dispatch<SetStateAction<string>>;
};

const CampsiteContext = createContext<ContextType>({
  code: "",
  setCode: () => {},
  id: "",
  setId: () => {},
});

export { CampsiteContext };
