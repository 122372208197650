import { useState } from "react";
import { ArrowDownIcon } from "@heroicons/react/20/solid";
import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";
import { eachMonthOfInterval, addMonths } from "date-fns";

import { Filter } from "@/components/layout/Filter";
import { EventList } from "@/components/layout/EventList";
import { H2, BodyText, Divider } from "@/components/typography";
import type {
  EventOverviewStoryblok,
  CampsiteStoryblok,
} from "@/component-types-sb";

type EventOverviewProps = {
  blok: EventOverviewStoryblok;
  searchParams: { [key: string]: string | string[] | undefined };
  draft: boolean;
};
const EventOverview = ({ blok }: EventOverviewProps) => {
  const [visibleMonthsCount, setVisibleMonthsCount] = useState(3);
  const [selectedCampsite, setSelectedCampsite] =
    useState<CampsiteStoryblok | null>(null);

  const months = eachMonthOfInterval({
    start: new Date(),
    end: addMonths(new Date(), visibleMonthsCount - 1),
  });
  const maxMonths = 12;

  return (
    <article className="w-full" {...storyblokEditable(blok)}>
      <header>
        {blok.header?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </header>

      <section className="py-8 text-center">
        <H2 as="h1" colorScheme="primary">
          {blok.title}
        </H2>
        <BodyText className="max-w-md mx-auto mt-2 text-center">
          {blok.description}
        </BodyText>

        <Divider />

        <div className="max-w-5xl mx-auto mb-24 grid gap-8 text-left">
          <Filter onSelect={setSelectedCampsite} type="events" />

          <div className="-my-6 overflow-hidden">
            {months.map((date, index) => (
              <EventList
                key={index}
                date={date}
                campsiteId={selectedCampsite?.uuid}
              />
            ))}
          </div>

          {visibleMonthsCount < maxMonths && (
            <button
              className="opacity-50 hover:opacity-100 flex flex-col items-center ml-32"
              onClick={() => {
                setVisibleMonthsCount(
                  Math.min(visibleMonthsCount + 3, maxMonths)
                );
              }}
            >
              weitere Monate anzeigen...
              <ArrowDownIcon className="w-4 h-4" />
            </button>
          )}
        </div>
      </section>
    </article>
  );
};

export default EventOverview;
