export const setProperty = (prop: string, value: string) => {
  if (typeof window !== "undefined") {
    const root = document.documentElement;
    root.style.setProperty(prop, value);
  }
};

export const getProperty = (prop: string) => {
  if (typeof window !== "undefined") {
    return getComputedStyle(document.documentElement).getPropertyValue(prop);
  }
};
