import { ReactNode } from "react";
import type { HTMLAttributes, MouseEvent } from "react";
import { storyblokEditable } from "@storyblok/react";
import Link from "next/link";
import type { MenuButtonStoryblok } from "@/component-types-sb";
import { clsx } from "clsx";

import { Button } from "@/components/layout/Button";

type MenuButtonProps = HTMLAttributes<HTMLElement> & {
  blok: MenuButtonStoryblok;
  children?: ReactNode;
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
};

const MenuButton = ({ blok, children, onClick, ...rest }: MenuButtonProps) => (
  <Link
    href={`/${blok.link?.cached_url}`}
    onClick={onClick}
    className={clsx("p-2.5", rest.className)}
    {...storyblokEditable(blok)}
  >
    <Button
      colorScheme="secondary"
      className="transition-transform duration-300 h-6 text-xs lg:h-auto lg:text-normal"
    >
      {blok.name}
    </Button>
  </Link>
);

export default MenuButton;
