import Link from "next/link";
import Image from "next/image";
import { storyblokEditable } from "@storyblok/react/rsc";
import { clsx } from "clsx";

import { Button } from "@/components/layout/Button";
import { BodyText } from "@/components/typography";
import type { ImageTeaserStoryblok } from "@/component-types-sb";
import imageLoader from "@/lib/utils/imageLoader";

type ImageTeaserProps = {
  blok: ImageTeaserStoryblok;
};

const ImageTeaser = ({ blok }: ImageTeaserProps) => (
  <section className="flex flex-col gap-4" {...storyblokEditable(blok)}>
    <Image
      className={clsx("object-cover w-full h-64 aspect-square", {
        "order-last": blok.reverse,
      })}
      loader={imageLoader}
      src={blok.image?.filename || ""}
      width={300}
      height={300}
      sizes="20vw"
      alt={blok.image?.alt || ""}
    />

    <h3 className="text-2xl text-brand-primary100 font-bold">
      {blok.headline}
    </h3>

    <BodyText className="max-w-md mx-auto mt-2">{blok.text}</BodyText>

    {blok.link?.cached_url && (
      <Link
        href={`${blok.link.linktype === "story" ? "/" : ""}${
          blok.link.cached_url
        }`}
        target={blok.link.target}
      >
        <Button colorScheme="primary" className="my-2">
          {blok.label}
        </Button>
      </Link>
    )}
  </section>
);

export default ImageTeaser;
