import Link from "next/link";
import Image from "next/image";
import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";

import { H4 } from "@/components/typography";
import type { ContactTeaserStoryblok } from "@/component-types-sb";

type ContactTeaserProps = {
  blok: ContactTeaserStoryblok;
};

const ContactTeaser = ({ blok }: ContactTeaserProps) => (
  <section className="pt-8 text-center" {...storyblokEditable(blok)}>
    <H4
      as="h3"
      colorScheme="primary"
      fontWeight="semibold"
      fontSize="lg"
      className="text-center mb-6"
    >
      {blok.overline}
    </H4>

    <div className="p-8 pb-16 text-left bg-brand-primary100">
      <div className="max-w-screen-xl mx-auto grid grid-cols-2 md:grid-cols-none md:grid-flow-col gap-8 prose-p:text-white prose-a:text-white prose-a:decoration-brand-secondary100">
        <div className="col-span-2 flex flex-col md:border-r-4 border-white md:px-8">
          <H4 as="h3" colorScheme="white">
            <span className="block font-bold text-3xl max-w-sm">
              {blok.headline}
            </span>
            <span className="block text-xl">{blok.subline}</span>
          </H4>

          <div className="flex flex-col gap-3 mt-4">
            {blok.buttons?.map((nestedBlok) => (
              <Link
                href={
                  nestedBlok.link?.linktype === "email"
                    ? `mailto:${nestedBlok.link.email}`
                    : nestedBlok.link?.cached_url
                }
                key={nestedBlok._uid}
              >
                <StoryblokComponent blok={nestedBlok} />
              </Link>
            ))}
          </div>
        </div>

        {blok.items?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}

        {blok.image?.filename && (
          <Image
            className="w-48 max-h-48 object-cover aspect-square self-start"
            src={blok.image.filename}
            width={192}
            height={192}
            alt={blok.image.alt || ""}
          />
        )}
      </div>
    </div>
  </section>
);

export default ContactTeaser;
