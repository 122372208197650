import { useState } from "react";
import Image from "next/image";
import { storyblokEditable } from "@storyblok/react/rsc";
import { ArrowDownIcon } from "@heroicons/react/20/solid";
import { orderBy } from "lodash";

import { H2, Divider, BodyText } from "@/components/typography";
import type { ImageGalleryStoryblok } from "@/component-types-sb";

type ImageGalleryProps = {
  blok: ImageGalleryStoryblok;
};

const isVideo = (url: string) => {
  if (url) {
    return url
      .substring(url.lastIndexOf("/") + 1)
      .toLowerCase()
      .endsWith(".mp4");
  }
  return false;
};

const ImageGallery = ({ blok }: ImageGalleryProps) => {
  const [visibleImagesCount, setVisibleImagesCount] = useState(8);
  const sortedImages = orderBy(blok.images, ["id"], ["desc"]);

  return (
    <article
      className="max-w-screen-xl mx-auto py-8"
      {...storyblokEditable(blok)}
    >
      <header className="text-center">
        <H2 colorScheme="primary">{blok.name}</H2>
        <BodyText className="max-w-md mx-auto mt-4 text-center">
          {blok.description}
        </BodyText>
      </header>

      <Divider />

      <section className="grid grid-cols-1 gap-2 mt-8 mx-2 lg:mx-0 lg:grid-cols-4">
        {sortedImages?.slice(0, visibleImagesCount).map((image) =>
          isVideo(image.filename) ? (
            <video
              key={image.id}
              controls
              autoPlay={false}
              // preload="metadata"
              width="100%"
              className="w-full h-full aspect-square object-cover bg-gray-200"
            >
              <source src={image.filename} type="video/mp4" />
              Ihr Browser unterstützt kein Video
            </video>
          ) : (
            <figure key={image.id}>
              <div className="relative block group bg-gradient-to-t from-black/80 to-30% lg:to-50% to-transparent">
                <Image
                  className="object-cover w-full transition duration-500 mix-blend-multiply group-hover:mix-blend-normal aspect-square"
                  src={image.filename}
                  width={300}
                  height={300}
                  sizes="20vw"
                  alt={image.alt || ""}
                  title={image.title || ""}
                />

                <figcaption className="absolute inset-0 flex flex-col items-start justify-end p-6 text-lg font-medium text-white">
                  {image.title}
                </figcaption>
              </div>
            </figure>
          )
        )}
      </section>

      <div className="w-full my-6 flex justify-center col-span-3">
        {visibleImagesCount < blok.images?.length && (
          <button
            className="opacity-50 hover:opacity-100 flex flex-col items-center"
            onClick={() => {
              setVisibleImagesCount(
                Math.min(visibleImagesCount + 8, blok.images?.length)
              );
            }}
          >
            {blok.images?.length - visibleImagesCount} weitere Bilder
            anzeigen...
            <ArrowDownIcon className="w-4 h-4" />
          </button>
        )}
      </div>
    </article>
  );
};

export default ImageGallery;
