"use client";

import Link from "next/link";
import { useEffect, useState } from "react";
import { track } from "@vercel/analytics";
import { startOfToday, endOfMonth, addMonths, parse } from "date-fns";
import { sortBy } from "lodash";
import { format, toDate } from "date-fns-tz";
import { de } from "date-fns/locale";

import { getEvents } from "@/lib/storyblok/getEvents";
import type { EventStoryblok } from "@/component-types-sb";

type EventTeaserProps = {
  campsiteId?: string;
  maxEvents?: number;
};

const EventTeaser = ({ campsiteId, maxEvents = 3 }: EventTeaserProps) => {
  const [events, setEvents] = useState<EventStoryblok[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getEvents({
        filter_query: {
          date: {
            gt_date: format(startOfToday(), "yyyy-MM-dd HH:mm"),
            lt_date: format(
              endOfMonth(addMonths(new Date(), 2)),
              "yyyy-MM-dd HH:mm"
            ),
          },
          campsite: { in: campsiteId || null },
        },
      });
      const sortedByDate = sortBy(data, ["content.date"]);
      setEvents(sortedByDate);
      setIsLoading(false);
    };
    fetchData();
  }, [campsiteId, maxEvents]);

  return (
    <div className="flex flex-col gap-2 mt-4">
      {isLoading && <Skeleton number={maxEvents} />}

      {!isLoading && events.length > 0 && (
        <>
          <Link href="/veranstaltungen">
            <span className="font-semibold text-brand-primary100">
              Nächste Veranstaltungen
            </span>
          </Link>
          <ul className="w-full text-sm">
            {events?.slice(0, maxEvents).map((blok) => (
              <li key={blok.id} className="px-2 py-2 border-b border-gray-200">
                <Link
                  href={blok.full_slug}
                  onClick={() => track("Event click")}
                  className="flex gap-2"
                >
                  <time dateTime={blok.content?.date}>
                    {blok.content?.date &&
                      format(
                        toDate(blok.content?.date, { timeZone: "GMT" }),
                        "dd.MM.",
                        { locale: de, timeZone: "Europe/Berlin" }
                      )}
                  </time>
                  {blok.name}
                </Link>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

const Skeleton = ({ number = 1 }) => (
  <>
    <div
      role="status"
      className="w-8/12 mb-1 h-6 bg-gray-200 animate-pulse"
    ></div>
    {Array(number)
      .fill(0)
      .map((_, index) => (
        <div
          key={index}
          className="w-12/12 my-1 h-4 bg-gray-200 animate-pulse"
        ></div>
      ))}
  </>
);

export { EventTeaser };
