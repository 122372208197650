import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";
import { renderRichText } from "@storyblok/react";
import { clsx } from "clsx";

import { H4, RichText } from "@/components/typography";
import type { TableGridStoryblok } from "@/component-types-sb";

type TableGrid = {
  blok: TableGridStoryblok;
};

const TableGrid = ({ blok }: TableGrid) => (
  <section
    className="max-w-screen-xl mx-auto py-8 text-center"
    {...storyblokEditable(blok)}
  >
    <H4
      as="h3"
      colorScheme="primary"
      fontWeight="bold"
      fontSize="lg"
      className="text-center mb-6"
    >
      {blok.headline}
    </H4>

    <div
      className={clsx("grid grid-cols-1 gap-2 px-8 md:px-0", {
        "md:grid-cols-2": blok.items && blok.items.length <= 3,
        "md:grid-cols-3": blok.items && blok.items.length > 3,
      })}
    >
      {blok.items?.map((nestedBlok) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}

      <RichText html={renderRichText(blok.description)} className="px-2" />
    </div>
  </section>
);

export default TableGrid;
