import { storyblokEditable } from "@storyblok/react/rsc";
import { H1 } from "@/components/typography";
import type { ColorScheme, FontSize } from "@/lib/types";
import type { HeadlineStoryblok } from "@/component-types-sb";

type HeadlineProps = {
  blok: HeadlineStoryblok;
};

const Headline = ({ blok }: HeadlineProps) => (
  <H1
    colorScheme={blok.color as ColorScheme}
    fontSize={blok.size as FontSize}
    uppercase={blok.uppercase}
    {...storyblokEditable(blok)}
  >
    {blok.text}
  </H1>
);

export default Headline;
