import { clsx } from "clsx";
import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";
import { H2, Divider, BodyText } from "@/components/typography";
import type { PriceListBoxesStoryblok } from "@/component-types-sb";

type PriceListBoxesPops = {
  blok: PriceListBoxesStoryblok;
};

const PriceListBoxes = ({ blok }: PriceListBoxesPops) => (
  <section className="py-8 text-center" {...storyblokEditable(blok)}>
    <H2 colorScheme="primary" className="break-words md:break-normal">
      {blok.headline}
    </H2>
    <BodyText className="max-w-2xl mx-auto mt-4 text-center">
      {blok.text}
    </BodyText>

    <Divider />

    <div
      className={clsx("py-8", {
        ["bg-gray-200"]:
          blok.style === "background" || blok.style === undefined,
        ["border-2 border-brand-primary100"]: blok.style === "border",
      })}
    >
      <div className="max-w-5xl mx-auto pb-8 text-left">
        {blok.items?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </div>
    </div>
  </section>
);

export default PriceListBoxes;
