import { useState, useCallback, useEffect } from "react";
import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";

import { Button } from "@/components/layout/Button";
import { Autocomplete } from "@/components/form";
import { H2, BodyText, Divider } from "@/components/typography";
import type { FaqStoryblok, CampsiteStoryblok } from "@/component-types-sb";

type Campsite = Pick<CampsiteStoryblok, "id" | "name" | "uuid">;

type FaqProps = {
  blok: FaqStoryblok;
  searchParams: { [key: string]: string | string[] | undefined };
};

const Faq = ({ blok, searchParams }: FaqProps) => {
  const campsite = searchParams?.["campsite[uuid]"];

  const [campsites, setCampsites] = useState<Campsite[] | null>(null);

  const getCampsites = useCallback(async () => {
    const res = await fetch("/api/campsites");
    const json = await res.json();
    setCampsites(json.data);
  }, []);

  useEffect(() => {
    getCampsites();
  }, [getCampsites]);

  return (
    <article className="w-full" {...storyblokEditable(blok)}>
      <header>
        {blok.header?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </header>

      <section className="py-8 text-center">
        <H2 as="h1" colorScheme="primary">
          {blok.title}
        </H2>
        <BodyText className="max-w-md mx-auto mt-2 text-center">
          {blok.description}
        </BodyText>

        <Divider />

        <div className="max-w-5xl mx-auto mb-24 text-left">
          <form
            action="/faq"
            method="get"
            className="bg-gray-300 flex items-end p-4 m-4 gap-4"
          >
            {campsites && (
              <Autocomplete
                data={campsites.map(({ id, name, uuid }) => ({
                  id,
                  name,
                  uuid,
                }))}
                name="campsite"
                placeholder="Wo suchst Du?"
                icon="location"
              />
            )}

            <Button as="button" type="submit" colorScheme="primary">
              Suchen
            </Button>
          </form>
        </div>

        <div className="max-w-5xl mx-auto pb-8 text-left">
          <div className="grid grid-cols-1 gap-8">
            {blok.questions?.map((nestedBlok) => (
              <StoryblokComponent
                blok={nestedBlok}
                key={nestedBlok._uid}
                campsite={campsite}
              />
            ))}
          </div>
        </div>
      </section>
    </article>
  );
};

export default Faq;
