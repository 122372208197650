import { ReactNode } from "react";
import { clsx } from "clsx";

type Props = {
  children: ReactNode;
  className?: string;
};

const BodyText = ({ children, ...rest }: Props) => (
  <p
    className={clsx(
      "text-lg text-left font-normal max-w-prose text-gray-500 mb-3",
      rest.className
    )}
  >
    {children}
  </p>
);

export { BodyText };
