import { ReactNode } from "react";
import type { MouseEvent, ComponentPropsWithoutRef, ElementType } from "react";
import { clsx } from "clsx";
import { ChevronRightIcon } from "@heroicons/react/20/solid";

import type { ColorScheme } from "@/lib/types";

type ButtonProps<TElementType extends ElementType> =
  ComponentPropsWithoutRef<TElementType> & {
    children?: ReactNode;
    arrow?: boolean;
    colorScheme?: ColorScheme;
    onClick?: (
      e: MouseEvent<HTMLButtonElement | HTMLAnchorElement | HTMLDivElement>
    ) => void;
    as?: Extract<TElementType, "button" | "a" | "div">;
    href?: string;
  };

const Button = <TElementType extends ElementType>({
  children,
  arrow = false,
  colorScheme,
  onClick,
  as,
  ...rest
}: ButtonProps<TElementType>) => {
  const ButtonElement = as ?? "div";

  return (
    <ButtonElement
      className={clsx(
        "uppercase inline-flex items-center px-3 py-2 text-sm font-medium text-center focus:ring-4 focus:outline-none focus:ring-blue-300",
        rest.className,
        {
          ["bg-brand-secondary100 text-white hover:bg-brand-secondary50"]:
            colorScheme === "secondary",
          ["bg-brand-primary100 text-white hover:bg-brand-primary50 hover:border-transparent"]:
            colorScheme === "primary",
          ["bg-white text-brand-primary100"]: colorScheme === "white",
          ["bg-transparent border text-white border-white hover:bg-brand-primary100 hover:border-transparent"]:
            colorScheme === "outline",
        }
      )}
      onClick={onClick}
      href={rest.href}
      type={rest.type}
      target={rest.target}
    >
      {children}

      {arrow && (
        <ChevronRightIcon className={clsx("w-4 h-4", { ["ml-2"]: children })} />
      )}
    </ButtonElement>
  );
};

export { Button };
export type { ColorScheme };
