import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";
import { H4 } from "@/components/typography";
import type { BoxGridStoryblok } from "@/component-types-sb";

type BoxGrid = {
  blok: BoxGridStoryblok;
};

const BoxGrid = ({ blok }: BoxGrid) => (
  <section
    className="max-w-screen-xl mx-auto py-8 text-center"
    {...storyblokEditable(blok)}
  >
    <H4
      as="h3"
      colorScheme="primary"
      fontWeight="semibold"
      fontSize="lg"
      className="text-center mb-6"
    >
      {blok.headline}
    </H4>

    <div className="grid grid-cols-1 md:grid-cols-2 gap-2 px-8 md:px-0">
      {blok.items?.map((nestedBlok) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
    </div>
  </section>
);

export default BoxGrid;
