import { storyblokEditable } from "@storyblok/react/rsc";
import { Card } from "@/components/layout/Card";
import { H2, BodyText, Divider } from "@/components/typography";
import type { CycleRouteOverviewStoryblok } from "@/component-types-sb";

type CycleRouteOverviewProps = {
  blok: CycleRouteOverviewStoryblok;
};

const CycleRouteOverview = ({ blok }: CycleRouteOverviewProps) => (
  <article className="w-full pb-32" {...storyblokEditable(blok)}>
    <section className="py-8 mx-2 lg:mx-0 text-center">
      <H2 as="h1" colorScheme="primary">
        {blok.title}
      </H2>
      <BodyText className="max-w-md mx-auto mt-2 text-center">
        {blok.description}
      </BodyText>

      <Divider />

      <div className="max-w-4xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-3">
        {blok?.cycleRoutes?.map((nestedBlok: any) => (
          <Card
            key={nestedBlok.id}
            image={nestedBlok.content?.image}
            href={nestedBlok.full_slug}
            name={nestedBlok.name}
            buttonLabel="Radweg entdecken"
          />
        ))}
      </div>
    </section>
  </article>
);

export default CycleRouteOverview;
