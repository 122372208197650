import type { HTMLAttributes } from "react";
import Link from "next/link";
import { clsx } from "clsx";

type BreadcrumbItem = {
  href?: string;
  title: string;
};

type BreadcrumbProps = HTMLAttributes<HTMLElement> & {
  items: BreadcrumbItem[];
  current?: string;
};

const Breadcrumb = ({ items, current, ...rest }: BreadcrumbProps) => {
  const home = items?.[0];
  const segments = items?.slice(1);

  return (
    <nav className={clsx("flex my-4", rest.className)} aria-label="Breadcrumb">
      <ol className="inline-flex items-center space-x-1 md:space-x-3">
        {home && (
          <li className="inline-flex items-center">
            <Link
              href={home?.href || "/"}
              className="inline-flex items-center text-sm font-medium text-gray-500 hover:text-brand-primary100"
            >
              {home.title}
            </Link>
          </li>
        )}
        {segments?.map((item) => (
          <li key={item.title} aria-current={item.href ? false : "page"}>
            <div className="flex items-center">
              <svg
                className="w-3 h-3 text-gray-200 mx-1"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>

              {item.href ? (
                <Link
                  href={item.href}
                  className="ml-1 text-sm font-medium text-gray-500 hover:text-brand-primary100 md:ml-2"
                >
                  {item.title}
                </Link>
              ) : (
                <span className="ml-1 text-sm font-medium text-gray-800 line-clamp-1">
                  {item.title}
                </span>
              )}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export { Breadcrumb };
