import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";
import type { PageStoryblok } from "@/component-types-sb";

type PageProps = {
  blok: PageStoryblok;
};

const Page = ({ blok }: PageProps) => (
  <article className="w-full" {...storyblokEditable(blok)}>
    <header>
      {blok.header?.map((nestedBlok) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
    </header>

    {blok.body?.map((nestedBlok) => (
      <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
    ))}

    {blok.footer?.map((nestedBlok) => (
      <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
    ))}
  </article>
);

export default Page;
