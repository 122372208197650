"use client";

import { usePathname } from "next/navigation";
import Script from "next/script";
import type { IiqCheckStoryblok } from "@/component-types-sb";
import { siteUrl } from "@/config/site";

type IiqCheckProps = {
  blok: IiqCheckStoryblok;
};

const IiqCheck = ({ blok }: IiqCheckProps) => {
  const pathname = usePathname();

  const id = blok.id;
  const r = siteUrl + pathname;
  return (
    <>
      <div
        id={`iiqcheck_widget_${id}`}
        className="max-w-[200px] mx-auto "
      ></div>
      <Script
        src={`https://app.iiq-check.de/hotels/${id}/widget_configurations/w.js?r=${r}`}
        strategy="afterInteractive"
      />
    </>
  );
};

export default IiqCheck;
