import { storyblokEditable } from "@storyblok/react/rsc";
import { Card } from "@/components/layout/Card";
import type { FeaturedPitchesStoryblok } from "@/component-types-sb";

type FeaturedPitchesPops = {
  blok: FeaturedPitchesStoryblok;
};

const FeaturedPitches = ({ blok }: FeaturedPitchesPops) => (
  <section className="py-8 mx-2 lg:mx-4" {...storyblokEditable(blok)}>
    <div className="grid grid-cols-1 md:grid-cols-4 gap-3">
      {blok.pitches?.map((nestedBlok: any) => (
        <Card
          key={nestedBlok.id}
          image={nestedBlok.content?.image}
          href={nestedBlok.full_slug}
          name={nestedBlok.name}
          text={nestedBlok.content?.lead}
        />
      ))}
    </div>
  </section>
);

FeaturedPitches.blockName = "featured_pitches";

export default FeaturedPitches;
