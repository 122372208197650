import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";
import { H4 } from "@/components/typography";
import type { AccordionStoryblok } from "@/component-types-sb";

type AccordionPops = {
  blok: AccordionStoryblok;
};

const Accordion = ({ blok }: AccordionPops) => {
  return (
    <div className="p-4 bg-white" {...storyblokEditable(blok)}>
      <H4 colorScheme="secondary" fontWeight="bold" className="pb-2">
        {blok.headline}
      </H4>

      <div className="w-full">
        {blok.items?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </div>
    </div>
  );
};

export default Accordion;
