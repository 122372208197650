import type { ComponentPropsWithoutRef, ElementType } from "react";
import { clsx } from "clsx";
import type { ColorScheme, FontSize } from "@/lib/types";

type Props<TElementType extends ElementType> =
  ComponentPropsWithoutRef<TElementType> & {
    colorScheme?: ColorScheme;
    fontSize?: FontSize;
    as?: Extract<TElementType, "h1" | "h2" | "h3" | "h4">;
  };

const H2 = <TElementType extends ElementType>({
  children,
  colorScheme = "secondary",
  fontSize = "5xl",
  as,
  ...rest
}: Props<TElementType>) => {
  const Headline = as ?? "h2";

  return (
    <Headline
      className={clsx(
        `text-4xl md:text-${fontSize} font-bold uppercase break-words mb-4`,
        rest.className,
        {
          ["text-brand-primary100"]: colorScheme === "primary",
          ["text-brand-secondary100"]: colorScheme === "secondary",
          ["text-white"]: colorScheme === "white",
        }
      )}
    >
      {children}
    </Headline>
  );
};

export { H2 };
