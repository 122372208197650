import Link from "next/link";
import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import { storyblokEditable } from "@storyblok/react/rsc";
import type { BacklinkStoryblok } from "@/component-types-sb";

type BacklinkProps = {
  blok: BacklinkStoryblok;
};

const Backlink = ({ blok }: BacklinkProps) => {
  if (!blok.link?.cached_url) {
    return null;
  }

  return (
    <div className="flex justify-center my-2">
      <Link
        href={`/${blok.link.cached_url}`}
        className="inline-flex px-2 py-4 lg:py-2 gap-1 uppercase text-sm font-semibold text-gray-500 hover:text-brand-primary100 border-gray-200"
        {...storyblokEditable(blok)}
      >
        <ArrowLeftIcon className="h-5 w-5" aria-hidden="true" />
        {blok.label}
      </Link>
    </div>
  );
};

export default Backlink;
