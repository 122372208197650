import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";

import { H2, Divider, BodyText } from "@/components/typography";
import type { ContactStoryblok } from "@/component-types-sb";

type ContactProps = {
  blok: ContactStoryblok;
};

const Contact = ({ blok }: ContactProps) => (
  <section id="oeffnungszeiten" className="pt-8" {...storyblokEditable(blok)}>
    <header className="text-center">
      <H2 colorScheme="primary">{blok.headline}</H2>
      <BodyText className="max-w-2xl mx-auto mt-4 text-center">
        {blok.text}
      </BodyText>
    </header>

    <Divider />

    {blok.items?.map((nestedBlok) => (
      <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
    ))}
  </section>
);

export default Contact;
