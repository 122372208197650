import dynamic from "next/dynamic";
import { Suspense } from "react";
import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";

import { Spinner } from "@/components/layout/Spinner";
import type { BookingStoryblok } from "@/component-types-sb";

const IFrame = dynamic(
  () => import("../../layout/IFrame").then((module) => module.IFrame),
  { ssr: false }
);

type BookingProps = {
  blok: BookingStoryblok;
};

const Booking = ({ blok }: BookingProps) => (
  <article className="w-full" {...storyblokEditable(blok)}>
    <header>
      {blok.header?.map((nestedBlok) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
    </header>

    <Suspense fallback={<Spinner />}>
      <IFrame className="mb-48" />
    </Suspense>
  </article>
);

export default Booking;
