import { storyblokEditable } from "@storyblok/react/rsc";
import { H1, BodyText } from "@/components/typography";
import type { TextTeaserStoryblok } from "@/component-types-sb";

type TextTeaserProps = {
  blok: TextTeaserStoryblok;
};

const TextTeaser = ({ blok }: TextTeaserProps) => (
  <section
    className="py-8 text-center w-full bg-brand-secondary100"
    {...storyblokEditable(blok)}
  >
    <H1 colorScheme="white" fontSize="5xl">
      {blok.headline}
    </H1>
    <BodyText className="max-w-md mx-auto mt-2 text-center">
      {blok.text}
    </BodyText>
  </section>
);

export default TextTeaser;
