import { storyblokEditable } from "@storyblok/react/rsc";
import Image from "next/image";
import Link from "next/link";

import { Button } from "@/components/layout/Button";
import { H4, BodyText } from "@/components/typography";
import type { CatalogTeaserStoryblok } from "@/component-types-sb";

type CatalogTeaserProps = {
  blok: CatalogTeaserStoryblok;
};

const CatalogTeaser = ({ blok }: CatalogTeaserProps) => (
  <div
    className="bg-brand-primary100 w-full py-16"
    {...storyblokEditable(blok)}
  >
    <Link
      href={blok.link?.cached_url || ""}
      className="mx-auto max-w-7xl border-l-0 md:border-l-4 border-white px-8 flex flex-col md:flex-row"
    >
      <div className="max-w-sm">
        <H4 as="h2" colorScheme="white" fontWeight="bold" fontSize="4xl">
          {blok.headline}
        </H4>
        <BodyText className="text-white pt-4">{blok.text}</BodyText>

        <Button colorScheme="outline" className="my-4">
          {blok.label}
        </Button>
      </div>
      {blok.image?.filename && (
        <Image
          src={blok.image.filename}
          width={380}
          height={236}
          alt={blok.image.alt || ""}
        />
      )}
    </Link>
  </div>
);

export default CatalogTeaser;
