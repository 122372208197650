import Link from "next/link";
// import { useSearchParams } from "next/navigation";
import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";

import { Breadcrumb } from "@/components/layout/Breadcrumb";
import { Button } from "@/components/layout/Button";
import { IndeedTeaser } from "@/components/layout/IndeedTeaser";
import { H2, H3, BodyText, Tag, Divider } from "@/components/typography";
import type { JobOverviewStoryblok, JobStoryblok } from "@/component-types-sb";
import { getJobs } from "@/lib/storyblok/getJobs";

type JobOverviewProps = {
  blok: JobOverviewStoryblok;
  searchParams: { [key: string]: string | string[] | undefined };
  draft: boolean;
};

const groupByCampsite = (
  items: JobStoryblok[]
): { [key: string]: JobStoryblok[] } =>
  items.reduce((acc: any, item) => {
    const name = item.content?.campsite?.name;
    if (name) {
      if (!acc[name]) {
        acc[name] = [];
      }

      acc[name].push(item);
    }
    return acc;
  }, {});

const JobOverview = async ({ blok, searchParams, draft }: JobOverviewProps) => {
  // const searchParams = useSearchParams();

  const filter_query = {
    campsite: { in: searchParams.campsite || null },
  };

  const { data: jobs } = await getJobs({ filter_query, draft });

  const storiesByCampsite = groupByCampsite(jobs.stories);

  // sort by name
  const keys = Object.keys(storiesByCampsite);
  keys.sort((a, b) => a.localeCompare(b));
  const sortedObject: { [key: string]: any[] } = {};
  for (const key of keys) {
    sortedObject[key] = storiesByCampsite[key];
  }

  return (
    <article className="w-full" {...storyblokEditable(blok)}>
      <header>
        {blok.header?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </header>

      <section className="py-8 text-center">
        <Breadcrumb
          items={[
            { href: "/karriere", title: "Karriere" },
            { href: "/jobs", title: "Stellenanzeigen" },
          ]}
          className="flex flex-col items-center"
        />
        <H2 as="h1" colorScheme="primary">
          {blok.title}
        </H2>
        <BodyText className="max-w-md mx-auto mt-2 text-center">
          {blok.description}
        </BodyText>

        <Divider />

        {Object.entries(sortedObject).map(([name, stories]) => (
          <div
            key={name}
            className="bg-gray-100 max-w-5xl p-4 mb-8 mx-auto text-left"
          >
            <H3 colorScheme="secondary">{name}</H3>
            <div className="divide-y divide-brand-primary100">
              {stories.map((nestedBlok) => (
                <div
                  key={nestedBlok.id}
                  className="flex flex-row py-4 mx-4 items-end justify-between"
                >
                  <div className="flex flex-col gap-2">
                    <div className="flex gap-2">
                      {nestedBlok.content?.employmentTypes?.map(
                        (employmentType: string) => (
                          <Tag key={employmentType} colorScheme="outline">
                            {employmentType}
                          </Tag>
                        )
                      )}
                      {nestedBlok.content?.employmentDuration && (
                        <Tag colorScheme="secondary">
                          {nestedBlok.content.employmentDuration}
                        </Tag>
                      )}
                    </div>

                    <Link href={nestedBlok.full_slug}>
                      <h3 className="text-xl font-bold text-gray-900">
                        {nestedBlok.content?.title}
                      </h3>
                      <span className="font-light">
                        {nestedBlok.content?.subline}
                      </span>
                    </Link>
                  </div>

                  <Link href={nestedBlok.full_slug}>
                    <Button colorScheme="primary">Mehr erfahren</Button>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        ))}
      </section>
      <IndeedTeaser />
    </article>
  );
};

export default JobOverview;
