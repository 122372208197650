import Link from "next/link";
import { Fragment } from "react";
import type { HTMLAttributes } from "react";
import { storyblokEditable } from "@storyblok/react";
import { Popover, Disclosure, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

import type { MenuLinkStoryblok } from "@/component-types-sb";
import { clsx } from "clsx";

type MenuLinkProps = HTMLAttributes<HTMLElement> & {
  blok: MenuLinkStoryblok;
  submenuStyle: "popover" | "disclosure";
};

type SubmenuProps = {
  name?: string;
  items: MenuLinkStoryblok[];
};

const flattenArray = (array: MenuLinkStoryblok[] = []) => {
  return array.reduce((acc: MenuLinkStoryblok[], current) => {
    acc.push({ ...current });
    if (current.submenu) {
      acc = acc.concat(flattenArray(current.submenu));
    }
    return acc;
  }, []);
};

const PopoverSubmenu = ({ name, items }: SubmenuProps) => {
  if (!items.length) {
    return null;
  }

  if (items.length == 1) {
    return (
      <Link
        href={`/${items[0].link?.cached_url}`}
        className="text-sm font-semibold text-gray-900 hover:text-brand-primary100"
      >
        {items[0].name}
      </Link>
    );
  }

  return (
    <Popover className="relative">
      <Popover.Button className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900 hover:text-brand-primary100">
        {name}
        <ChevronDownIcon
          className="h-5 w-5 flex-none text-gray-400"
          aria-hidden="true"
        />
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute -left-8 top-full z-30 mt-3 overflow-hidden bg-white shadow-lg ring-1 ring-gray-900/5">
          {({ close }) => (
            <div className="py-4 px-8 grid gap-2">
              {items.map((item) => (
                <Link
                  key={item._uid}
                  href={`/${item.link?.cached_url}`}
                  onClick={() => close()}
                  className="text-sm font-semibold leading-6 text-gray-900 hover:text-brand-primary100"
                >
                  {item.name}
                </Link>
              ))}
            </div>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

const DisclosureSubmenu = ({ name, items }: SubmenuProps) => {
  if (!items.length) {
    return null;
  }

  if (items.length == 1) {
    return (
      <Link
        href={`/${items[0].link?.cached_url}`}
        className="text-sm font-semibold text-gray-900 hover:text-brand-primary100"
      >
        {items[0].name}
      </Link>
    );
  }

  return (
    <Disclosure as="div" className="-mx-3">
      {({ open }: { open: boolean }) => (
        <>
          <Disclosure.Button className="flex items-center gap-x-1 pl-4 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50">
            {name}
            <ChevronDownIcon
              className={clsx(open ? "rotate-180" : "", "h-5 w-5 flex-none")}
              aria-hidden="true"
            />
          </Disclosure.Button>
          <Disclosure.Panel className="mt-2 space-y-2">
            {flattenArray(items)?.map((item: MenuLinkStoryblok) => (
              <Disclosure.Button
                key={item._uid}
                as="a"
                href={`/${item.link?.cached_url}`}
                className="block py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
              >
                {item.name}
              </Disclosure.Button>
            ))}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

const MenuLink = ({ blok, submenuStyle = "popover" }: MenuLinkProps) => {
  return blok.submenu?.length ? (
    submenuStyle == "disclosure" ? (
      <DisclosureSubmenu name={blok.name} items={blok.submenu} />
    ) : (
      <PopoverSubmenu name={blok.name} items={blok.submenu} />
    )
  ) : (
    <Link
      href={`/${blok.link?.cached_url}`}
      className="text-sm font-semibold text-gray-900 hover:text-brand-primary100"
      {...storyblokEditable(blok)}
    >
      {blok.name}
    </Link>
  );
};

export default MenuLink;
