import { storyblokEditable } from "@storyblok/react/rsc";
import { renderRichText } from "@storyblok/react";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { clsx } from "clsx";

import { H4, RichText } from "@/components/typography";
import type { PriceListItemStoryblok } from "@/component-types-sb";

type PriceListItemProps = {
  blok: PriceListItemStoryblok;
};

const PriceListItem = ({ blok }: PriceListItemProps) => (
  <Disclosure {...storyblokEditable(blok)}>
    {({ open }) => (
      <div className="border-t-2 border-gray-200 py-2">
        <Disclosure.Button className="flex w-full justify-between py-2 text-left font-medium text-brand-primary100 focus:outline-none focus-visible:ring focus-visible:ring-brand-primary200 focus-visible:ring-opacity-75">
          <H4 fontSize="xl" fontWeight="semibold">
            {blok.name}
          </H4>
          <ChevronDownIcon
            className={clsx("h-5 w-5 text-brand-primary100", {
              ["rotate-180 transform"]: open,
            })}
          />
        </Disclosure.Button>
        <Disclosure.Panel className="pt-1 pb-4 text-sm text-gray-500">
          <RichText html={renderRichText(blok.text)} />

          <ul className="mt-4">
            {blok.files?.map((file) => (
              <li key={file.id}>
                <a
                  href={file?.filename}
                  target="_blank"
                  download
                  className="inline-flex gap-1 items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="100%"
                    viewBox="0 0 142 142"
                    className="w-6 h-6"
                  >
                    <path
                      fill="#e30613"
                      fillRule="evenodd"
                      d="M56.179 117.1H31.88V76.758h6.235v34.107H56.18v6.235Zm10.636-59.468v-2.498H57.98v11.218h2.793v-4.213h5.486v-2.498h-5.486v-2.008h6.042ZM48.41 63.82v-6.156h1.732c1.91 0 3.282 1.273 3.282 3.07 0 1.796-1.371 3.086-3.282 3.086H48.41Zm-2.792 2.53h4.098c3.854 0 6.564-2.334 6.564-5.616 0-3.283-2.712-5.601-6.564-5.601h-4.098v11.218Zm-8.116-6.057v-2.612h2.156c.964 0 1.65.523 1.65 1.306s-.686 1.306-1.65 1.306h-2.156Zm-2.792 6.058h2.792V62.84h2.417c2.466 0 4.262-1.585 4.262-3.854 0-2.27-1.796-3.854-4.262-3.854h-5.21V66.35Zm-7.375-19.027h33.435c7.38 0 13.418 6.039 13.418 13.418 0 7.38-6.037 13.418-13.418 13.418H27.336V47.325Zm56.64 52.38v2.612h8.238l-17.807 20.44-17.422-20.44h7.523v-2.613h19.467Zm0-3.466v2.805H64.507v-2.805h19.467Zm0-3.409v2.749H64.507V92.83h19.467Zm0-3.575v2.915H64.507v-2.915h19.467Zm0-2.804v2.144H64.507v-2.144h19.467Zm0-2.034v1.375H64.507v-1.375h19.467ZM64.507 82.74h19.467v1.018H64.508V82.74Zm50.153-33.144V117.1H92.307v-6.234h16.12V51.589H82.754l-.125-24.452H38.115V44.73H31.88V20.902h53.792l28.99 28.695Z"
                    />
                  </svg>
                  {file?.title || file?.filename}
                </a>
              </li>
            ))}
          </ul>
        </Disclosure.Panel>
      </div>
    )}
  </Disclosure>
);

export default PriceListItem;
